import { createTheme } from '@mui/material/styles';

export const color = {
  stylinkPurple: '#a890fe',
  black: '#000',
  white: '#FFF'
};

export const theme = createTheme({
  palette: {
    primary: {
      main: color.stylinkPurple,
      contrastText: color.white
    },
    secondary: {
      main: color.black,
      contrastText: color.white
    }
  },
  typography: {
    fontFamily: ['Montserrat'].join(','),
    h5: {
      fontWeight: 600,
      fontSize: '20px'
    }
  }
});
