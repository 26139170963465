import * as Icons from '@mui/icons-material'
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import useInterval from '../../useInterval'
import MenuDrop from './MenuDrop'
import { useSnackbar } from 'notistack'
import influencerService from '../../service/InfluencerService'
import { TodoOverviewItem } from '../../types/types'

const TodoIcon = ({ type, feedback }: Pick<TodoOverviewItem, 'type' | 'feedback'>) => {
  switch (type) {
    case 'uploadContent':
      return feedback ? <Icons.Publish fontSize='small' /> : <Icons.Upload fontSize='small' />
    case 'awaitDelivery':
      return <Icons.LocalShipping fontSize='small' />
    case 'awaitContentFeedback':
      return <Icons.NoteAlt fontSize='small' />
    default:
      return <Icons.Error fontSize='small' />
  }
}
const todoText = ({ type, feedback }: Pick<TodoOverviewItem, 'type' | 'feedback'>) => {
  switch (type) {
    case 'uploadContent':
      return feedback ? 'Zweiter Upload' : 'Erster Upload'
    case 'awaitDelivery':
      return 'Produkt versenden'
    case 'awaitContentFeedback':
      return 'Feedback einsehen'
    default:
      return `What? ${type}`
  }
}
const menuOptions = ['TodoId kopieren'] as const
const TodoCard = ({ item: { type, endDate, todoId, feedback, campaignId, influencerId } }: { item: TodoOverviewItem }) => {
  const [now, setNow] = useState(Date.now())
  useInterval(5_000, () => setNow(Date.now()))
  const backgroundColor = now > endDate ? '#FF7D7D' : 'white'
  const link = `https://eu-central-1.console.aws.amazon.com/dynamodbv2/home?region=eu-central-1#edit-item?itemMode=2&pk=${campaignId}&route=ROUTE_ITEM_EXPLORER&sk=${influencerId}&table=CampaignInfluencers`
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [resetLoading, setResetLoading] = useState<boolean | undefined>(undefined)

  const onResetFeedback = async () => {
    setResetLoading(true)
    const key = enqueueSnackbar('Reset Lädt', { variant: 'info' })
    try {
      await influencerService.resetFeedback({ campaignId, influencerId })
      setResetLoading(false)
    } catch (e) {
      enqueueSnackbar(`Fehler`, { variant: 'error' })
      setResetLoading(undefined)
    } finally {
      closeSnackbar(key)
    }
  }

  if (resetLoading == false) return <></>

  return (
    <Stack p={1} border={2} borderColor='gray' borderRadius={3} direction='row' alignItems='center' spacing={1} sx={{ backgroundColor }}>
      <TodoIcon type={type} feedback={feedback} />
      <Stack spacing={0} width='100%'>
        <Link target='_blank' href={link} underline='none' sx={{ color: 'blue' }}>
          <Typography variant='caption'>{todoText({ type, feedback })}</Typography>
        </Link>
        <Typography variant='caption'>{moment(endDate).format('DD.MM.YYYY')}</Typography>
      </Stack>
      {feedback && type == 'uploadContent' && (
        <Tooltip title='Feedback zurücksetzen'>
          <IconButton disabled={resetLoading}>
            <Icons.SettingsBackupRestore onClick={() => onResetFeedback()} />
          </IconButton>
        </Tooltip>
      )}
      <MenuDrop options={menuOptions} clipboardSource={{ [menuOptions[0]]: todoId }} ml='100%'>
        <Icons.ArrowDropDown sx={{ color: 'black' }} />
      </MenuDrop>
    </Stack>
  )
}

export default TodoCard
