import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { ShippingCodeDetails } from '../../types/types'

type Props = {
  id: string
  open: boolean
  values: ShippingCodeDetails
  onClose: () => void
}
export const ShippingDialog = (props: Props) => {
  const { id, open, values, onClose } = props

  if (!values) return <></>

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth id={id}>
      <DialogTitle variant='h4'>Shipping Info</DialogTitle>
      <DialogContent>
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Box sx={{ display: 'inline-flex' }}>
              <Typography variant='h5'>Shipping Service:</Typography>
              <Typography variant='body2' sx={{ ml: '10px', mt: '4px' }}>
                {values.shippingService}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'inline-flex' }}>
              <Typography variant='h5'>Shipping Code:</Typography>
              <Typography variant='body2' sx={{ ml: '10px', mt: '4px' }}>
                {values.shippingCode}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
