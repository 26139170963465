import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ResponsiveDrawer } from '../ResponsiveDrawer'
import { useAuth } from '../context/AuthContext'

interface PrivateRouteProps {
  childComponent: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ childComponent }) => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  return <ResponsiveDrawer childComponent={childComponent} />
}

export default PrivateRoute
