import { Send as IconSend, UploadFile as IconUploadFile } from '@mui/icons-material'
import { IconButton, Stack, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { useChat } from '../../context/ChatContext'
import { MessageType } from '../../types/types'
import { State, uploadToS3 } from '../../util'
import agent from '../../agent'

export const MessageField = ({ message, setMessage }: State<'message', string>) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [messageField, inputFile] = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)]
  const [sending, setSending] = useState(false)
  const { sendMessage, selectedConvo } = useChat()

  const clearMessage = () => {
    setMessage('')
    if (messageField.current) messageField.current.value = ''
  }

  const onSendMessage = async () => {
    if (!selectedConvo || !message.length) return
    await sendMessage(selectedConvo.conversationId, message, MessageType.text)
    clearMessage()
  }

  const upload = async (file?: File) => {
    if (!file || !selectedConvo) return
    const key = enqueueSnackbar(`Sending: ${file.name}`, { variant: 'info', persist: true })
    try {
      const url = (await agent.Chat.getChatMediaUploadUrl({ influencerId: selectedConvo.influencerId })).url
      await uploadToS3(url, file)
      const ext = file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
      if (['.mp4', '.mov'].includes(ext)) await sendMessage(selectedConvo.conversationId, url, MessageType.video)
      else if (['.png', '.jpg', '.jpeg'].includes(ext)) await sendMessage(selectedConvo.conversationId, url, MessageType.photo)
      else throw `Invalid file type: ${ext}`
      clearMessage()
    } catch (e) {
      enqueueSnackbar(typeof e == 'string' ? e : 'Failed to send', { variant: 'error' })
    } finally {
      closeSnackbar(key)
      setSending(false)
    }
    if (inputFile.current) inputFile.current.value = '' // reset value to be able to upload same thing
  }

  return (
    <>
      <input type='file' ref={inputFile} style={{ display: 'none' }} onChange={e => upload(e.target.files?.[0])} />
      <Stack direction='row' paddingTop={2} alignItems='center' spacing={1}>
        <TextField
          value={message}
          fullWidth
          multiline
          id='outlined-basic'
          label='Text message'
          variant='outlined'
          style={{ maxWidth: '100%' }}
          onChange={e => setMessage(e.target.value)}
        />
        <IconButton size='small' sx={{ minHeight: 0, minWidth: 0 }} onClick={() => inputFile.current?.click()} disabled={sending}>
          <IconUploadFile />
        </IconButton>
        <IconButton size='small' sx={{ minHeight: 0, minWidth: 0 }} onClick={() => onSendMessage()} disabled={sending || !message.length}>
          <IconSend />
        </IconButton>
      </Stack>
    </>
  )
}
