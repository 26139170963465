import { Grid, Typography } from '@mui/material';
import React from 'react';

const ErrorScreen = ({ message }: { message: string }) => {
  return (
    <Grid
      container
      sx={{ height: 'calc(100vh - 64px)' }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography>{message}</Typography>
    </Grid>
  );
};

export default ErrorScreen;
