import agent from '../agent';
import { UGCStatus } from '../types/types';

class InfluencerService {
  verifyInfluencer({ influencerId }: { influencerId: string }) {
    return agent.Influencers.verifyUgc({ influencerId, ugcStatus: UGCStatus.Accepted });
  }


  getInfluencersNew() {
    return agent.Influencers.getAllNew({});
  }

  denyInfluencer = agent.Influencers.denyInfluencer;
  verifyUgc = agent.Influencers.verifyUgc;
  getAllInfluencers = agent.Influencers.getAll;
  toggleContentCv = agent.Influencers.toggleContentCv;
  uploadCredit = agent.Influencers.uploadCredit;
  getInfluencer = agent.Influencers.getInfluencer;
  getCampaignsOfCreator = agent.Influencers.getCampaignsOfCreator;
  resetFeedback = agent.Influencers.resetFeedback;
  getInvoices = agent.Influencers.getInvoices
  deleteInfluencer = agent.Influencers.delete
  updateInfluencer = agent.Influencers.updateInfluencer
  spoofAsInfluencer = agent.Influencers.spoofAsInfluencer
}

export default new InfluencerService();
