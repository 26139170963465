/* eslint-disable @typescript-eslint/no-explicit-any */

// https://stackoverflow.com/questions/49580725/is-it-possible-to-restrict-typescript-object-to-contain-only-properties-defined
type Impossible<K extends keyof any> = Record<K, never>
export type NoExtraProperties<T, U extends T> = U & Impossible<Exclude<keyof U, keyof T>>

type Destruct<Return, Target extends string, Allowed extends string, Recursed extends boolean = false> = Return extends { [K in Target]: infer C }
  ? C extends Allowed
    ? Recursed extends true
      ? never
      : Destruct<Return, C, Allowed, true>
    : C extends (...args: any) => infer R
    ? R
    : never
  : never

function Enum<Cases extends { readonly [key: string]: string }>(cases: Cases) {
  type Value = Cases[keyof Cases]
  type Matches = { [P in Value]: ((p: P) => any) | Exclude<Value, P> }
  type MatchesWithDefault = { [P in Value]?: ((p: P) => any) | Exclude<Value | 'default', P> } & { default: (() => any) | Value }
  const original = cases
  const enumObj = {
    fold(value: string, cases: Record<string, string | ((p: string) => any)>): any {
      const func = cases[value as any]
      if (typeof func == 'function') return func(value as any)
      else if (typeof func == 'undefined') {
        if ((cases as any).default) return (cases as any).default()
        throw new Error(`Invalid enum case: ${value}`)
      } else {
        if (!Object.values(original).includes(func) && func != 'default') throw new Error(`Invalid case to redirect to ${func}`)
        return enumObj.fold(func, cases as any)
      }
    },
    values: Object.values(cases)
  }
  type Enum<Cases> = Cases & {
    fold<Target extends string, Return extends MatchesWithDefault>(value: Target, cases: NoExtraProperties<MatchesWithDefault, Return>): Destruct<Return, Target, Value>
    fold<Target extends string, Return extends Matches>(value: Target, cases: NoExtraProperties<Matches, Return>): Destruct<Return, Target, Value>
    values: Value[]
  }
  return Object.seal(Object.assign(enumObj, cases)) as Enum<Cases>
}
namespace Enum {
  export function fromArray<Cases extends readonly string[]>(...cases: Cases) {
    return Enum(Object.fromEntries(cases.map(c => [c, c])) as { [P in Cases[number]]: P })
  }
  export function fromArrayCapitalize<Cases extends readonly string[]>(...cases: Cases) {
    return Enum(Object.fromEntries(cases.map(c => [c[0].toUpperCase() + c.substr(1).toLowerCase(), c])) as { [P in Cases[number] as `${Capitalize<P>}`]: P })
  }
  export function fromArrayUppercase<Cases extends readonly string[]>(...cases: Cases) {
    return Enum(Object.fromEntries(cases.map(c => [c.toUpperCase(), c])) as { [P in Cases[number] as `${Uppercase<P>}`]: P })
  }
}
type Enum<T extends { values: string[] }> = T['values'][number]
export default Enum
