import * as Icons from '@mui/icons-material'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Stack, TextField, Typography } from '@mui/material'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { extractUserMessageFromError } from '../../util'
import campaignService from '../../service/CampaignService'
import CommentDialog from '../comments/CommentDialog'
import { Comment, TodoOverviewItem } from '../../types/types'
import { useTodoOverview } from '../../context/TodoOverviewContext'
import { ShippingDialog } from './ShippingDialog'

const DeleteButton = ({ item }: { item: TodoOverviewItem }) => {
  const { updateItem } = useTodoOverview()
  const [deleting, setDeleting] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const texts = {
    awaitDelivery: { title: 'Bist du sicher, das die Brand nicht mehr versenden wird?', body: 'Die Kampagne wird abgeschlossen und der Creator erhält 10€.' },
    uploadContent: {
      title: item.feedback
        ? 'Bist du sicher, das der Creator nicht das Feedback einarbeiten oder hochladen wird?'
        : 'Bist du sicher, das der Creator nicht hochladen wird?',
      body: 'Die Kampagne wird abgeschlossen und die Brand erhält ein kostenloses Video.'
    },
    seeFeedback: {
      title: 'Bist du sicher, das der Creator nicht das Feedback einarbeiten oder hochladen wird?',
      body: 'Die Kampagne wird abgeschlossen und die Brand erhält ein kostenloses Video.'
    }
  } as Partial<Record<typeof item.type, { title: string; body: string }>>

  return (
    <>
      <Dialog open={showDelete} onClose={() => setShowDelete(false)}>
        <DialogTitle>{texts[item.type]?.title}</DialogTitle>
        <DialogContent>{texts[item.type]?.body}</DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={async () => {
              setShowDelete(false)
              try {
                setDeleting(true)
                await campaignService.deleteCampaignInfluencer({ campaignId: item.campaignId, influencerId: item.influencerId, todoId: item.todoId })
                updateItem(item, true)
              } catch (e) {
                const message = extractUserMessageFromError(e)
                enqueueSnackbar(message || `${e}`, { variant: 'error' })
              } finally {
                setDeleting(false)
              }
            }}>
            Ja
          </Button>
          <Button color='secondary' variant='outlined' onClick={() => setShowDelete(false)}>
            Nein
          </Button>
        </DialogActions>
      </Dialog>
      {deleting ? (
        <CircularProgress />
      ) : (
        <IconButton disabled={item.type === 'awaitContentFeedback'} onClick={() => setShowDelete(true)}>
          <Icons.Delete />
        </IconButton>
      )}
    </>
  )
}

const EndDateButton = ({ item }: { item: TodoOverviewItem }) => {
  const { updateItem } = useTodoOverview()
  const [newEndDate, setNewEndDate] = useState(item.endDate)
  const [settingEndDate, setSettingEndDate] = useState(false)
  const pickerRef = useRef<null | HTMLInputElement>(null)

  const onDateChange = async (newEndDate: number) => {
    try {
      setSettingEndDate(true)
      await campaignService.setTodoEndDate({ campaignId: item.campaignId, influencerId: item.influencerId, todoId: item.todoId, endDate: newEndDate })
      setNewEndDate(newEndDate)
      updateItem({ ...item, endDate: newEndDate, oldEndDates: [...item.oldEndDates, item.endDate] })
    } catch (e) {
      const message = extractUserMessageFromError(e)
      enqueueSnackbar(message || `${e}`, { variant: 'error' })
    } finally {
      setSettingEndDate(false)
    }
  }

  if (settingEndDate) return <CircularProgress />

  return (
    <Stack alignItems='center' width='70px'>
      <TextField
        sx={{ visibility: 'hidden', width: 0, height: 0 }}
        type='date'
        value={moment(newEndDate).format('YYYY-MM-DD')}
        onChange={e => onDateChange(moment(e.target.value, 'YYYY-MM-DD').valueOf())}
        InputLabelProps={{ shrink: true }}
        InputProps={{ ref: pickerRef }}
        size='small'
        inputProps={{ style: { fontSize: 12 } }}
      />
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <IconButton onClick={() => (pickerRef.current?.childNodes.item(0) as any)?.showPicker()}>
          <Icons.Timer sx={{ color: item.oldEndDates.at(-1) ? 'red' : undefined }} />
        </IconButton>
      }
      <Typography component='div' fontSize={11}>
        {(item.oldEndDates.at(-1) && moment(item.oldEndDates.at(-1)).format('DD.MM.')) ?? ''}
      </Typography>
    </Stack>
  )
}

const CommentButton = ({ item }: { item: TodoOverviewItem }) => {
  const { updateItem } = useTodoOverview()
  const [openComment, setOpenComment] = useState<string | false>(false)

  const handleCommentClose = (comments: Comment[]) => {
    setOpenComment(false)
    updateItem({ ...item, comments })
  }

  return (
    <>
      <IconButton
        onClick={() => setOpenComment(`${item.campaignId}_${item.influencerId}` === openComment ? false : `${item.campaignId}_${item.influencerId}`)}
        color={`${item.campaignId}_${item.influencerId}` === openComment ? 'info' : undefined}>
        <Icons.Comment fontSize='small' />
      </IconButton>
      {`${item.campaignId}_${item.influencerId}` === openComment && (
        <CommentDialog
          campaignId={item.campaignId}
          influencerId={item.influencerId}
          comments={item.comments}
          id={`${item.campaignId}_${item.influencerId}`}
          open={openComment}
          onClose={handleCommentClose}
        />
      )}
    </>
  )
}

const ShippingButton = ({ item }: { item: TodoOverviewItem }) => {
  const [showShipping, setShowShipping] = useState<boolean>(false)

  return (
    <>
      <IconButton onClick={() => setShowShipping(true)}>
        <Icons.LocalShipping fontSize='small' />
      </IconButton>
      <ShippingDialog id={`${item.campaignId}_${item.influencerId}`} open={showShipping} values={item.shippingDetails} onClose={() => setShowShipping(false)} />
    </>
  )
}

const TodoActions = ({ item }: { item: TodoOverviewItem }) => (
  <Stack p={1} direction='row' alignItems='center' spacing={1}>
    <DeleteButton item={item} />
    <EndDateButton item={item} />
    <CommentButton item={item} />
    {item.shippingDetails && <ShippingButton item={item} />}
  </Stack>
)

export default TodoActions
