import { GridRowsProp } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PersistedDataGrid, { CustomGridColDef } from '../../components/dataGrid/PersistedDataGrid'
import InfluencerService from '../../service/InfluencerService'
import { Influencer } from '../../components/influencers/credit/Credit'

export type CampaignInfluencers = Awaited<ReturnType<typeof InfluencerService.getCampaignsOfCreator>>

const InfluencerCampaigns = () => {
  const [rows, setRows] = useState<GridRowsProp | undefined>(undefined)
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const influencerId = queryParams.get('influencerId')

  const [influencer, setInfluencer] = useState<Influencer | undefined>(undefined)

  useEffect(() => {
    const fetchCampaigns = async () => {
      if (influencerId) {
        let campaigns: CampaignInfluencers = location.state?.campaigns
        if (!campaigns) campaigns = await InfluencerService.getCampaignsOfCreator({ influencerId })
        const influencerData = await InfluencerService.getInfluencer({ influencerId })
        setRows(
          campaigns.map(campaign => ({
            id: campaign.campaignId,
            ...campaign
          })) as GridRowsProp
        )
        setInfluencer(influencerData) // Assuming 'name' is a property of the 'InfluencerMapper' type
      }
    }

    fetchCampaigns()
  }, [influencerId])

  console.log({ influencer })
  const campaignColumns: CustomGridColDef[] = [
    { field: 'brandId', headerName: 'Brand ID', width: 150, aggregable: false },
    { field: 'brandName', headerName: 'Brand Name', width: 150, aggregable: false },
    { field: 'campaignId', headerName: 'Campaign ID', width: 150, aggregable: false },
    { field: 'influencerId', headerName: 'Influencer ID', width: 150, aggregable: false },
    { field: 'statusInfo', headerName: 'Status', width: 150, aggregable: false },
    {
      field: 'upload',
      headerName: 'Upload',
      width: 150,
      type: 'video',
      headerAlign: 'center',
      valueGetter: params => {
        const value = params as { contentLinks: { link: string }[] }
        if (value?.contentLinks?.length > 0) {
          return value.contentLinks[0].link
        }
        return ''
      }
    },
    {
      field: 'link',
      headerName: 'Campaign Link',
      width: 250,
      renderCell: params => {
        const brandId = params.row.brandId
        const campaignId = params.row.campaignId
        const campaignLink = `https://ugcapp.stylink.com/content/${brandId}/${campaignId}`

        return (
          <a href={campaignLink} target='_blank' rel='noopener noreferrer'>
            Link
          </a>
        )
      }
    }
  ]

  if (!influencer) {
    return <div>Loading...</div>
  }

  return (
    <>
      <div style={{ fontWeight: 'bold', marginBottom: 20 }}>{influencer.stylinkData?.name}</div>
      <PersistedDataGrid rows={rows} columns={campaignColumns} />
    </>
  )
}

export default InfluencerCampaigns
