import { faSignOutAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../src/assets/logo/nano_lang_black@2x.png'
import { pages, PageTypes } from './Pages'
import { useAuth } from './context/AuthContext'
import { useChat } from './context/ChatContext'

const drawerWidth = 240

interface ResponsiveDrawerProps {
  window?: () => Window
  childComponent: React.ReactNode
}

type Page = {
  title: string
  description: string
  value: string
  icon: IconDefinition
  type: PageTypes
}

const PageView = ({ p }: { p: Page }) => {
  const isChat = p.value === 'chat'
  const { conversations } = useChat()

  const unread = isChat && conversations ? conversations.filter(c => c.unread).length : 0
  const location = useLocation();
  
  const path = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname
  const isActive = path === p.value
  
  return (
    <Link key={p.value} to={`/${p.value}`}>
      <ListItem button sx={{ maxWidth: '100%' }} style={{ backgroundColor: isActive ? '#a890fe' : 'white' }}>
        <ListItemIcon>
          <FontAwesomeIcon icon={p.icon} size='lg' color={isActive ? 'white' : ''} />
        </ListItemIcon>
        <ListItemText primary={p.title} style={{ color: isActive ? 'white' : '' }} />
        {unread > 0 && (
          <Box display='flex' justifyContent='center' alignItems='center' sx={{ borderRadius: 50, background: 'red', p: 1, width: 60, height: 22 }}>
            <Typography variant='caption' color='whitesmoke'>
              {`${unread > 9 ? '9+' : unread}`}
            </Typography>
          </Box>
        )}
      </ListItem>
    </Link>
  )
}

export const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = (props: ResponsiveDrawerProps) => {
  const { window, childComponent } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const { logout } = useAuth()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleLogout = async () => {
    await logout()
    navigate('/login')
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {pages
          .filter(p => p.type === PageTypes.BRANDS)
          .map(p => (
            <PageView key={p.value} p={p} />
          ))}
      </List>
      <Divider />
      <List>
        {pages
          .filter(p => p.type === PageTypes.INFLUENCERS)
          .map(p => (
            <PageView key={p.value} p={p} />
          ))}
      </List>
      <Divider />
      <List>
        {pages
          .filter(p => p.type === PageTypes.STATISTICS)
          .map(p => (
            <PageView key={p.value} p={p} />
          ))}
      </List>
      <List>
        <ListItem button key='logout' onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faSignOutAlt} size='lg' />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
    </div>
  )

  const container = window ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}>
        <Toolbar>
          <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            <Link to='/home'>
              <img src={logo} alt='Logo' height='16px' />
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box component='main' sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <Toolbar />
        {childComponent}
      </Box>
    </Box>
  )
}
