import * as Icons from '@mui/icons-material'
import { Badge, CircularProgress, IconButton, Link, Paper, Popper, Stack, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import ChatView from './ChatView'
import MenuDrop from './MenuDrop'
import { useChat } from '../../context/ChatContext'
import { TodoOverviewItem } from '../../types/types'
import { enqueueSnackbar } from 'notistack'
import { extractUserMessageFromError } from '../../util'
import InfluencerService from '../../service/InfluencerService'
import { useTodoOverview } from '../../context/TodoOverviewContext'

const ShadowBan = ({ isShadowBanned, influencerId, item }: Pick<TodoOverviewItem, 'isShadowBanned' | 'influencerId'> & { item: TodoOverviewItem }) => {
  const { updateItem } = useTodoOverview()
  const [banning, setBanning] = useState(false)

  const onClick = async () => {
    try {
      setBanning(true)
      await InfluencerService.updateInfluencer({ influencerId, isShadowBanned: !isShadowBanned })
      setBanning(false)
      updateItem({ ...item, isShadowBanned: !isShadowBanned })
    } catch (e) {
      setBanning(false)
      const message = extractUserMessageFromError(e)
      enqueueSnackbar(message || `${e}`, { variant: 'error' })
    }
  }

  if (banning) return <CircularProgress />
  const Icon = isShadowBanned ? Icons.Undo : Icons.DoDisturb
  return (
    <IconButton onClick={onClick}>
      <Icon sx={{ color: 'black' }} fontSize='small' />
    </IconButton>
  )
}

const InfluencerCard = ({ item }: { item: TodoOverviewItem }) => {
  const { setSelectedConvo } = useChat()
  const [opennedChat, setOpennedChat] = useState<string | null>(null)
  const { conversations } = useChat()

  const conversation = conversations.find(c => c.influencerId === item.influencerId)
  const conversationId = conversation?.conversationId
  const open = opennedChat === item.todoId
  const cardRef = useRef<null | HTMLElement>(null)

  const onChatClick = () => {
    if (!conversationId) return
    setSelectedConvo(conversation, false)
    setOpennedChat(open ? null : item.todoId)
  }

  const unread = conversation?.unread ? 1 : 0

  return (
    <Stack direction='row' alignItems='center' width='100%'>
      <Stack
        component='div'
        ref={cardRef as React.RefObject<HTMLDivElement>}
        p={1.5}
        border={2}
        borderColor='gray'
        borderRadius={3}
        direction='row'
        alignItems='center'
        spacing={1}
        width='inherit'>
        {item.influencerImage && <img src={item.influencerImage} width='50' height='40' style={{ objectFit: 'contain' }} alt='' />}
        <Stack spacing={0} width='inherit'>
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} variant='caption'>
            {item.fullName}
          </Typography>
          <Link
            underline='none'
            sx={{
              color: 'blue',
              textAlign: 'left',
              minHeight: 0,
              minWidth: 0,
              p: 0,
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
            onClick={() => {
              navigator.clipboard.writeText(item.address)
              enqueueSnackbar('Copied!', { autoHideDuration: 500, variant: 'info' })
            }}>
            <Typography variant='caption'>{item.address}</Typography>
          </Link>
        </Stack>
        <Badge invisible={unread === 0} badgeContent={unread} color='error' max={9}>
          <IconButton onClick={onChatClick} color={open ? 'info' : undefined}>
            <Icons.Message fontSize='small' />
          </IconButton>
        </Badge>
        <Popper open={open} anchorEl={cardRef.current} placement='bottom' modifiers={[{ name: 'flip', options: { fallbackPlacements: ['bottom'] } }]}>
          <Paper elevation={5} sx={{ border: 1, p: 1, bgcolor: 'background.paper', height: 500, width: cardRef.current?.clientWidth }}>
            {conversationId ? <ChatView /> : <CircularProgress />}
          </Paper>
        </Popper>
        <ShadowBan isShadowBanned={item.isShadowBanned} influencerId={item.influencerId} item={item} />
        <MenuDrop options={['InfluencerId kopieren']} clipboardSource={{ 'InfluencerId kopieren': item.influencerId }}>
          <Icons.ArrowDropDown sx={{ color: 'black' }} />
        </MenuDrop>
      </Stack>
    </Stack>
  )
}

export default InfluencerCard
