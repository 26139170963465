import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

interface PublicRouteProps {
  childComponent: React.ReactNode
}

const PublicRoute: React.FC<PublicRouteProps> = ({ childComponent }) => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate to='/home' />
  }

  return <>{childComponent}</>
}

export default PublicRoute
