import * as Icons from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useImmer } from 'use-immer'
import agent from '../../agent'
import config from '../../configs'
import campaignService from '../../service/CampaignService'
import influencerService from '../../service/InfluencerService'
import styles from '../../style/influencers.module.css'
import { MessageType, UGCStatus } from '../../types/types'
import { bucketUrl, cloudfrontUrl, extractUserMessageFromError, State, uploadToS3 } from '../../util'
import CommentDialog from '../comments/CommentDialog'
import { InputFile } from '../common/InputFile'

type Content = Awaited<ReturnType<typeof campaignService.getContentCheck>>[number]

const Player = ({ url, title }: { url: string; title: string }) => (
  <Stack width='100%'>
    <Typography variant='h5' alignSelf='center'>
      {title}
    </Typography>
    <ReactPlayer className={styles.profilePic} controls url={url} height={150} width='100%' light />
  </Stack>
)
const Header = ({ onClick, text }: { onClick: () => void; text: string }) => (
  <Stack spacing={2} direction='row'>
    <Typography variant='h5' m='auto auto auto 0'>
      {text}
    </Typography>
    <IconButton onClick={onClick}>
      <Icons.OpenInBrowser />
    </IconButton>
  </Stack>
)
const Column = ({ title, child: children }: { title: string; child: JSX.Element | string[] }) => {
  const [dialog, setDialog] = useState(false)
  const jsx = Array.isArray(children)
    ? children.map((c, i) => <Typography key={c + i} sx={dialog ? {} : { textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${i + 1}. ${c}`}</Typography>)
    : children
  return (
    <Stack>
      <Dialog open={dialog}>
        <Box p={1}>
          <Stack direction='row' alignContent='center' alignItems='center'>
            <Typography variant='h5' pb={1}>
              {title}
            </Typography>
            <Box width='100%' />
            <IconButton onClick={() => setDialog(false)}>
              <Icons.Close />
            </IconButton>
          </Stack>
          {jsx}
        </Box>
      </Dialog>
      <Header text={title} onClick={() => setDialog(true)} />
      {jsx}
    </Stack>
  )
}
const DenyDialog = ({ open, setOpen, deny }: State<'open', boolean> & { deny(_: string[]): Promise<void> }) => {
  const [reasons, setReasons] = useImmer([''])
  const [loading, setLoading] = useState(false)

  const manage = async () => {
    setLoading(true)
    try {
      await deny(reasons)
      setOpen(false)
    } finally {
      setLoading(true)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Content deny reasons</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {reasons.map((r, i) => (
            <Stack key={i} direction='row' alignItems='center'>
              <TextField
                value={r}
                onChange={e => {
                  const value = e.currentTarget?.value || ''
                  setReasons(p => {
                    p[i] = value
                  })
                }}
              />
              <IconButton
                onClick={() =>
                  setReasons(p => {
                    p.splice(i, 1)
                  })
                }>
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
          <IconButton
            onClick={() =>
              setReasons(p => {
                p.push('')
              })
            }>
            <AddIcon />
          </IconButton>
        </Stack>
        <LoadingButton variant='contained' loading={loading} onClick={manage}>
          Deny
        </LoadingButton>
      </DialogContent>
    </Dialog>
  )
}
const CardView = ({ content, setContents }: { content: Content } & State<'contents', Content[]>) => {
  const {
    firstContent,
    secondContent,
    campaignId,
    influencerId,
    feedback,
    language,
    mentions,
    scenes,
    videoDuration,
    videoFormat,
    todoId,
    title,
    brandId,
    brandName,
    influencerName,
    comments
  } = content
  const [managing, setManaging] = useState(false)
  const [openDeny, setOpenDeny] = useState(false)
  const [open, setOpen] = useState(false)
  const [openComment, setOpenComment] = useState<string | false>(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const remove = () => setContents(contents => contents.filter(_c => _c !== content))
  const sendFeedback = async (denyReasons: string[]) => {
    const { conversationId } = await agent.Chat.createConversation({ influencerId })
    await agent.Chat.sendMessage({ conversationId, content: `${denyReasons.join('\n')}`, type: MessageType.text })
  }
  const manageContent = async (data: { accept: true } | { accept: false; denyReasons: string[] }) => {
    setManaging(true)
    const name = data.accept ? 'Accepting' : 'Denying'
    const key = enqueueSnackbar(name, { variant: 'info' })
    try {
      await campaignService.approveInfluencerContent({ influencerId, campaignId })
      if (!data.accept) await sendFeedback(data.denyReasons)
      remove()
    } catch (e) {
      const userError = extractUserMessageFromError(e)
      enqueueSnackbar(userError || `Fehler beim ${name}`, { variant: 'error' })
    }
    closeSnackbar(key)
    setManaging(false)
  }
  const ban = async (denyReasons: string[]) => {
    setManaging(true)
    const key = enqueueSnackbar('Banning', { variant: 'info' })
    try {
      await campaignService.deleteCampaignInfluencer({ campaignId, todoId, influencerId })
      await influencerService.denyInfluencer({ influencerId, ugcStatus: UGCStatus.Banned })
      await sendFeedback(denyReasons)
      remove()
    } catch (e) {
      const userError = extractUserMessageFromError(e)
      enqueueSnackbar(userError || `Fehler`, { variant: 'error' })
    }
    closeSnackbar(key)
    setManaging(false)
  }
  const reset = async () => {
    setManaging(true)
    const key = enqueueSnackbar('Resetting', { variant: 'info' })
    try {
      await campaignService.resetUploadTodo({ campaignId, influencerId, todoId })
      remove()
    } catch (e) {
      const userError = extractUserMessageFromError(e)
      enqueueSnackbar(userError || `Fehler`, { variant: 'error' })
    }
    closeSnackbar(key)
    setManaging(false)
  }
  const upload = async (file: File) => {
    setManaging(true)
    const key = enqueueSnackbar('Uploading', { variant: 'info', persist: true })
    try {
      const url = (await campaignService.getMediaUploadUrl({ brandId, influencerId, campaignId })).url
      await uploadToS3(url, file)
      await campaignService.resetUploadTodo({ campaignId, influencerId, todoId, content: url })
      setContents(contents => {
        if (content.secondContent) {
          content.secondContent = url.replace(bucketUrl, cloudfrontUrl)
        } else {
          content.firstContent = url.replace(bucketUrl, cloudfrontUrl)
        }
        return [...contents]
      })
    } catch (e) {
      const userError = extractUserMessageFromError(e)
      enqueueSnackbar(userError || `Fehler`, { variant: 'error' })
    }
    closeSnackbar(key)
    setManaging(false)
  }

  const handleOpenComment = (value: string | false) => setOpenComment(value)

  return (
    <Card elevation={5}>
      <DenyDialog open={openDeny} setOpen={setOpenDeny} deny={reasons => (secondContent ? manageContent({ accept: false, denyReasons: reasons }) : ban(reasons))} />
      <InputFile open={open} setOpen={setOpen} onFile={upload} />
      <CardMedia>
        <Stack direction='row'>
          <Player url={firstContent} title='1st Upload' />
          <Box width='10px' />
          {secondContent && <Player url={secondContent} title='2nd Upload' />}
        </Stack>
      </CardMedia>
      <CardContent>
        <Stack direction='row' spacing={2} p={1}>
          <Column
            title='Information'
            child={
              <>
                <Stack direction='row' spacing={2}>
                  {Object.entries({ Campaign: campaignId, Influencer: influencerId }).map(p => (
                    <Link
                      key={p[0]}
                      component='button'
                      variant='body2'
                      onClick={() => {
                        navigator.clipboard.writeText(p[1])
                        enqueueSnackbar(`Copied! ${p[0]}`, { autoHideDuration: 500, variant: 'info' })
                      }}>
                      {p[0]}
                    </Link>
                  ))}
                </Stack>
                <Link
                  variant='body2'
                  sx={{ display: 'flex', mb: 2, '&:hover': { textDecoration: 'underline' } }}
                  underline='always'
                  target='_blank'
                  href={`${process.env.REACT_APP_WEB_URL}/content/${brandId}/${campaignId}/creators`}>
                  Link to campaign
                </Link>
                {Object.entries({
                  Language: language,
                  'Video Format': videoFormat,
                  'Video Duration': videoDuration,
                  CampaignId: campaignId,
                  InfluencerId: influencerId,
                  Titel: title,
                  Brand: brandName,
                  InfluencerName: influencerName
                }).map(p => (
                  <Typography key={p[0]}>{`${p[0]}: ${p[1]}`}</Typography>
                ))}
              </>
            }
          />
          <Column title='Scenes' child={scenes} />
          <Column title='Mentions' child={mentions} />
          <Column title='Feedback' child={feedback} />
        </Stack>
      </CardContent>
      <Stack direction='row' spacing={2} display='flex' justifyContent='center' pb={1}>
        <Button variant='contained' color='error' onClick={() => setOpenDeny(true)} disabled={managing}>
          {secondContent ? 'Ablehnen' : 'Ban'}
        </Button>
        <Button variant='contained' color='success' onClick={() => manageContent({ accept: true })} disabled={managing}>
          Annehmen
        </Button>
        <Button variant='contained' color='warning' onClick={reset} disabled={managing}>
          Reset
        </Button>
        <Button variant='contained' color='secondary' onClick={() => setOpen(true)} disabled={managing}>
          Upload
        </Button>
        <Button
          variant='contained'
          color='info'
          onClick={() => handleOpenComment(openComment === `${campaignId}_${influencerId}` ? false : `${campaignId}_${influencerId}`)}
          disabled={managing}>
          Comment
        </Button>
        {openComment === `${campaignId}_${influencerId}` && (
          <CommentDialog
            id={`${campaignId}_${influencerId}`}
            open={openComment}
            onClose={() => handleOpenComment(false)}
            comments={comments}
            campaignId={campaignId}
            influencerId={influencerId}
          />
        )}
      </Stack>
    </Card>
  )
}
export const AcceptContent = () => {
  const [contents, setContents] = useState<Content[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const loadContents = async () => {
    const key = enqueueSnackbar('Loading', { variant: 'info' })
    try {
      const contents = await campaignService.getContentCheck({})
      setContents(contents)
    } catch (e) {
      const userError = extractUserMessageFromError(e)
      enqueueSnackbar(userError || 'Fehler beim loadContents', { variant: 'error' })
      setError(true)
    } finally {
      setLoading(false)
    }
    closeSnackbar(key)
  }

  useEffect(() => {
    loadContents()
  }, [])

  if (loading) return <CircularProgress />
  if (error || !contents) return <Typography>Something went wrong</Typography>
  if (contents.length === 0) return <Typography>No content to accept</Typography>

  return (
    <Stack spacing={2}>
      {contents.map(c => (
        <CardView key={c.influencerId} content={c} contents={contents} setContents={setContents} />
      ))}
    </Stack>
  )
}
