export default {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "com.freebeeapp"
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://mnkwl5qmzd.execute-api.eu-central-1.amazonaws.com/dev"
  },
  cognito: {
    IDENTITY_POOL_ID: 'eu-central-1:c79919f2-3a0c-4519-ab50-7ad290c30265',
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_bUhBhaRok',
    APP_CLIENT_ID: '39o190a83qtgmrdjk5rh5a3lla',
  }
};

export const adminChatBrandId = process.env.REACT_APP_STAGE != 'production' ? '0c104d63-7bff-483c-9972-cdd14b019b7c': 'd41c9a51-da75-4a0e-ab33-853beef64e88'