/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dispatch, SetStateAction, useState } from 'react'

export const getFormattedDate = (millis: number) => {
  const date = new Date(millis)
  const year = date.getFullYear()
  const month = `0${date.getMonth() + 1}`.slice(-2)
  const day = `0${date.getDate()}`.slice(-2)

  return `${day}.${month}.${year}`
}

export const uploadToS3 = async (signedUrl: string, file: File) => {
    const url = new URL(signedUrl);
    await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'video/mp4'
      },
      body: file
    });
}

export const getAge = (birthday?: Date) => {
  if (!birthday) return undefined
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function check(condition: any, error?: string | Error): asserts condition {
  if (!condition) {
    if (error instanceof Error) throw error
    else throw new Error(error ?? 'Failed a check')
  }
}

/**
 * Strips the object's fields and saves only those that were written in keys. Types of keys and return are strict
 * @param obj fields to strip from
 * @param keys keep given keys
 * @returns stripped object
 */
export function keep<TObj extends object, TList extends (keyof TObj)[]>(obj: TObj, ...keys: TList) {
  const newObj: Record<string, any> = {}
  for (const key of keys) newObj[key as string] = obj[key]
  return newObj as { [Key in TList[number]]: TObj[Key] }
}

export const mock = (funcs: { [key: string]: (..._: any) => any }) => {
  if (process.env.REACT_APP_STAGE != 'development' && process.env.REACT_APP_STAGE != 'local') return
  const win = window as unknown as Record<string, Record<string, unknown>>
  const mock = win.mock ?? (win.mock = {})
  Object.assign(mock, funcs)
}

export type State<Name extends string, T> = { [N in `${Name}` | `set${Capitalize<Name>}`]: N extends `set${string}` ? Dispatch<SetStateAction<T>> : T }
export type Empty = object

export function extractUserMessageFromError(e: any) {
  if (e?.status != 422) return undefined
  const { errorMessage, message }: { errorMessage?: string; message?: string } = JSON.parse(e.response?.text ?? '{}')
  return (errorMessage ?? message)?.substring(6)
}

export async function dummyRequest(delay: number, error?: Error) {
  return await new Promise((r, reject) => setTimeout(() => (error ? reject(error) : r(undefined)), delay))
}

// https://stackoverflow.com/a/24782004/11988215
export function chunk<T>(arr: T[], chunkSize: number) {
  if (chunkSize <= 0) throw 'Invalid chunk size'
  const R: T[][] = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize))
  return R
}

export namespace Compare {
  export function byArray(a: unknown[], b: unknown[]) {
    return a.length == b.length && a.all((el, i) => el == b[i])
  }
  export function byKeys(a: Record<string, unknown> | undefined, b: Record<string, unknown> | undefined) {
    if (a && b) {
      const a_keys = Object.keys(a)
      const b_keys = Object.keys(b)
      return byArray(a_keys, b_keys) && a_keys.all(key => a[key] == b[key])
    } else {
      return (a == null) == (b == null)
    }
  }
}

export class Result<Value> {
  constructor(public readonly value?: Value, public readonly error?: object | string) {}

  fold<T0, T1, T2>(onLoading: () => T0, onValue: (_: Value) => T1, onError: (_: any) => T2) {
    if (this.value !== undefined) return onValue(this.value)
    if (this.error !== undefined) return onError(this.error)
    return onLoading()
  }

  toString() {
    return this.fold(
      () => 'loading',
      v => (v === null ? 'success' : `success: ${v}`),
      e => {
        if (typeof e === 'string') return `failure: ${e}`
        if (e instanceof Error) return `failure: ${e.message}`
        return `failure: ${JSON.stringify(e)}`
      }
    )
  }

  static success(): Result<null>

  static success<Value>(v: Value): Result<Value>

  static success<Value>(value?: Value) {
    return new Result(value ?? null)
  }

  static failure<Value>(error: object | string | any) {
    return new Result<Value>(undefined, error)
  }

  static loading<Value = null>() {
    return new Result<Value>()
  }
}

/** @deprecated useless idea */
export const useResult = <Value = null>(defaultValue?: Value) => {
  const [result, setResult] = useState(new Result(defaultValue))
  return [
    result,
    async (block: () => Value extends null ? void | Promise<void> : Value | Promise<Value>) => {
      try {
        const value = block()
        if (value instanceof Promise) {
          setResult(Result.loading())
          await value
        }
        setResult(Result.success((value as any) ?? null))
      } catch (e) {
        console.error(e)
        setResult(Result.failure(e))
      }
    }
  ] as const
}

declare global {
  interface Array<T> {
    /**
     * Wraps this array in Promise.all
     */
    awaitAll(): Promise<Awaited<T>[]>
    /**
     * Works exactly like .sort, but this function creates new array
     * @returns sorted array
     */
    sorted(compareFn?: (a: T, b: T) => number): T[]
    /**
     * Returns sorted in ascending order, based on value of element
     * @returns sorted array
     */
    ascendingSorted(value: (v: T) => number): T[]
    /**
     * Filter out undefined or null
     */
    filterNotNull(): NonNullable<T>[]
    /**
     * Works exactly like .map, except it doesn't include null elements
     */
    compactMap<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: this): NonNullable<U>[]
    /**
     * Check if all elements match the predicate
     */
    all(predicate: (value: T, index: number) => boolean): boolean
    /**
     * Check if at least one element matches the predicate
     */
    any(predicate: (value: T) => boolean): boolean

    /**
     * Works like .reverse(), but returns new reversed array
     */
    reversed(): T[]
  }
}

Array.prototype.awaitAll = async function () {
  return Promise.all(this)
}
Array.prototype.sorted = function <T>(compareFn?: (a: T, b: T) => number) {
  const sortedArray = new Array(...this)
  sortedArray.sort(compareFn)
  return sortedArray
}
Array.prototype.ascendingSorted = function <T>(value: (v: T) => number = v => (v as any).valueOf() as number) {
  const sortedArray = [...this]
  sortedArray.sort((a, b) => value(a) - value(b))
  return sortedArray
}
Array.prototype.filterNotNull = function <T>() {
  return this.filter((v?: T) => v != null)
}
Array.prototype.compactMap = function <T>(callbackfn: (value: T, index: number, array: T[]) => any) {
  return this.map(callbackfn).filterNotNull()
}
Array.prototype.all = function <T>(predicate: (value: T, index: number) => boolean) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [value, i] of this.map((el, i) => [el, i] as const)) if (!predicate(value, i)) return false
  return true
}
Array.prototype.any = function <T>(predicate: (value: T) => boolean) {
  // eslint-disable-next-line no-restricted-syntax
  for (const value of this) if (predicate(value)) return true
  return false
}
Array.prototype.reversed = function () {
  const array = [...this]
  return array.reverse()
}

export const bucketUrl = process.env.REACT_APP_STAGE == 'production' ? 'https://stylink-ugc-production.s3.eu-central-1.amazonaws.com/' : 'https://getnano-development.s3.eu-central-1.amazonaws.com/'
export const cloudfrontUrl = process.env.REACT_APP_STAGE == 'production' ? 'https://duwhup56nt2mn.cloudfront.net/' : 'https://d2hajobaa1dyhd.cloudfront.net/'
