import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react'
import { enqueueSnackbar, closeSnackbar } from 'notistack'
import campaignService from '../service/CampaignService'
import { TodoOverviewItem } from '../types/types'

interface TodoOverviewContextType {
  items: TodoOverviewItem[]
  loadItems: () => Promise<void>
  updateItem: (updatedItem: TodoOverviewItem, remove?: boolean) => void
}

const TodoOverviewContext = createContext<TodoOverviewContextType | undefined>(undefined)

export const TodoOverviewProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<TodoOverviewItem[]>([])

  const loadItems = useCallback(async () => {
    const key = enqueueSnackbar('Loading Todo Overview...', { key: 'loading', persist: true, variant: 'info' })
    try {
      const data = await campaignService.getTodoOverview({})
      setItems(data)
    } catch (error) {
      console.error('Failed to load items', error)
    } finally {
      closeSnackbar(key)
    }
  }, [])

  const updateItem = useCallback((updatedItem: TodoOverviewItem, remove = false) => {
    if (remove) setItems(prevItems => prevItems.filter(item => item.todoId !== updatedItem.todoId))
    else setItems(prevItems => prevItems.map(item => (item.todoId === updatedItem.todoId ? updatedItem : item)))
  }, [])

  return <TodoOverviewContext.Provider value={{ items, loadItems, updateItem }}>{children}</TodoOverviewContext.Provider>
}

export const useTodoOverview = () => {
  const context = useContext(TodoOverviewContext)
  if (context === undefined) {
    throw new Error('useTodoOverview must be used within a TodoOverviewProvider')
  }
  return context
}
