import * as Icons from '@mui/icons-material'
import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import MenuDrop from './MenuDrop'
import { TodoOverviewItem } from '../../types/types'

const menuOptions = ['BrandId kopieren', 'CampaignId kopieren'] as const
const CampaignCard = ({ item: { brandId, campaignId, campaignImage, brandName, productName, contactEmail } }: { item: TodoOverviewItem }) => {
  const truncText = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } as const
  return (
    <Stack p={1} border={2} borderColor='gray' borderRadius={3} direction='row' alignItems='center' spacing={1}>
      <img src={campaignImage} height='40' width='50' style={{ objectFit: 'contain' }} alt='' />
      <Stack spacing={0} width='100%'>
        <Link
          target='_blank'
          href={`${process.env.REACT_APP_WEB_URL}/content/${brandId}/${campaignId}/applications`}
          sx={{ color: 'blue', ...truncText }}
          underline='none'>
          <Typography variant='caption'>{`${brandName}: ${productName}`}</Typography>
        </Link>
        <Link href={`mailto:${contactEmail}`} sx={{ color: 'blue', ...truncText }} underline='none'>
          <Typography variant='caption'>{contactEmail}</Typography>
        </Link>
      </Stack>
      <MenuDrop options={menuOptions} clipboardSource={{ [menuOptions[0]]: brandId, [menuOptions[1]]: campaignId }}>
        <Icons.ArrowDropDown sx={{ color: 'black' }} />
      </MenuDrop>
    </Stack>
  )
}

export default CampaignCard
