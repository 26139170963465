import { Button } from '@mui/material'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import InfluencerService from '../../service/InfluencerService'

interface CampaignsColumnProps {
  params: { row: { influencerId: string } }
}

export const CampaignsColumn: React.FC<CampaignsColumnProps> = ({ params }) => {
  const navigate = useNavigate()

  const fetchCampaigns = async () => {
    const key = enqueueSnackbar('Lade Aufträge', { variant: 'info', autoHideDuration: null })
    try {
      const response = await InfluencerService.getCampaignsOfCreator({
        influencerId: params.row.influencerId
      })
      return response
    } catch (error) {
      enqueueSnackbar('Fehler beim Laden', { variant: 'error' })
      console.error('Error fetching campaigns:', error)
    } finally {
      closeSnackbar(key)
    }
  }

  const handleViewCampaigns = async () => {
    const res = await fetchCampaigns()
    if (res && res.length == 0) {
      enqueueSnackbar('Keine Aufträge')
      return
    }

    const url = `/influencers/campaigns?influencerId=${params.row.influencerId}`
    navigate(url, { state: { campaigns: res } })
  }

  return (
    <Button variant='contained' color='primary' style={{ position: 'relative' }} onClick={() => handleViewCampaigns()}>
      Aufträge einsehen
    </Button>
  )
}
