import UpdateIcon from '@mui/icons-material/Update'
import { ListItemButton, ListItemIcon, Stack } from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useChat } from '../../context/ChatContext'
import { MessageView } from './MessageView'

export const MessagesView = ({ height = '650px' }: { height?: string }) => {
  const [stayInBottom, listDiv] = [useRef(true), useRef<HTMLDivElement>(null)]
  const { messages, lastMessageKey, loadMessages, selectedConvo } = useChat()
  const hasMoreMessages = lastMessageKey != null

  const scrollToFirstMessage = () => {
    const first = listDiv.current?.children.item(hasMoreMessages ? 1 : 0)
    if (first) first.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' })
  }
  const scrollToLastMessage = () => {
    const count = listDiv.current?.children.length ?? 0
    const last = listDiv.current?.children.item(count - 1)
    if (last) last.scrollIntoView({ block: 'start', inline: 'end', behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToLastMessage()
  }, [messages])

  useEffect(() => {
    if (stayInBottom.current) {
      scrollToLastMessage()
    }
  }, [messages.length])

  const onScroll = useCallback(
    event => {
      const dom = event.target as HTMLDivElement
      const maxScrollTop = dom.scrollHeight - dom.clientHeight
      const canScroll = maxScrollTop > 0
      stayInBottom.current = canScroll && maxScrollTop - dom.scrollTop <= 25
      // if you scroll fast enough it could theoretically load the chat wrong. But it's unlikely.
      if (hasMoreMessages && dom.scrollTop <= 0) {
        loadMessages(true).then(scrollToFirstMessage)
      }
    },
    [loadMessages]
  )

  if (!selectedConvo) return <>Fehler: Keine Konversation ausgewählt.</>

  return (
    <Stack ref={listDiv} height={height} width='100%' overflow='scroll' spacing={2} sx={{ pr: 5 }} onScroll={onScroll}>
      {hasMoreMessages && (
        <ListItemButton onClick={() => loadMessages(true)} sx={{ justifyContent: 'center' }}>
          <ListItemIcon>
            <UpdateIcon sx={{ fontSize: '50px' }} />
          </ListItemIcon>
        </ListItemButton>
      )}
      {messages.map(m => (
        <MessageView key={m.messageId} message={m} isCurrentUser={m.authorId == selectedConvo.brandId} />
      ))}
    </Stack>
  )
}
