import { Equalizer, Person, PersonAdd, Redeem } from '@mui/icons-material'
import { CircularProgress, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import StarIcon from '@mui/icons-material/Star'
import { Box } from '@mui/system'
import moment from 'moment'
import campaignService from '../../../service/CampaignService'
import { Empty } from '../../../util'
// eslint-disable-next-line import/no-cycle
import { TableHeaderTypes } from '../CampaignsTable'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { Comment as CommentIcon } from '@mui/icons-material'
import CommentDialog from '../../comments/CommentDialog'

export type Campaign = Awaited<ReturnType<typeof campaignService.getOnlineCampaigns>>[number]

const OnlineTable = () => {
  const [sortBy, setSortBy] = useState(TableHeaderTypes.NAME)
  const [ascending, setAscending] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [loading, setLoading] = useState<Error | Empty>()
  const [open, setOpen] = useState<string | false>(false)

  const load = async (initial?: boolean) => {
    setLoading(undefined)
    try {
      const newCampaigns = await campaignService.getOnlineCampaigns()
      !initial && setCampaigns(prev => [...(prev ?? []), ...newCampaigns])
      initial && setCampaigns(newCampaigns)
      setLoading({})
    } catch (e) {
      console.error(e)
      setLoading(e as Error)
    }
  }

  const handleOpen = (value: string | false) => setOpen(value)

  useEffect(() => {
    load()
  }, [])

  if (loading == null && !open) return <CircularProgress />
  if (loading instanceof Error) return <>{`${loading}`}</>

  const sortTable = (a: Campaign, b: Campaign) => {
    if (!ascending) {
      const temp = b
      b = a
      a = temp
    }
    switch (sortBy) {
      case TableHeaderTypes.NAME:
        return a.brandName.localeCompare(b.brandName)
      case TableHeaderTypes.TITLE:
        if (a.productInfo && b.productInfo) return a.productInfo.name.localeCompare(b.productInfo.name)
        return 0
      case TableHeaderTypes.APPLICANTS:
        return a.applied - b.applied
      case TableHeaderTypes.PARTICIPANTS:
        return a.accepted - b.accepted
      case TableHeaderTypes.SLOTS:
        return a.numberOfSlots - b.numberOfSlots
      case TableHeaderTypes.CAMPAIGN_LOCALE:
        return a.locale.localeCompare(b.locale)
      case TableHeaderTypes.VERIFIED_DATE:
        return (a.isVerifiedTimestamp ?? 0) - (b.isVerifiedTimestamp ?? 0)
      default:
        return a.brandName.localeCompare(b.brandName)
    }
  }

  const headers = [
    TableHeaderTypes.NAME,
    TableHeaderTypes.TITLE,
    TableHeaderTypes.APPLICANTS,
    TableHeaderTypes.PARTICIPANTS,
    TableHeaderTypes.SLOTS,
    TableHeaderTypes.CAMPAIGN_LOCALE,
    TableHeaderTypes.VERIFIED_DATE,
    TableHeaderTypes.ACTIONS
  ]

  const onTableHead = (type: TableHeaderTypes) => (type == sortBy ? setAscending(prev => !prev) : setSortBy(type))
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          {headers.map(header => (
            <TableCell key={header} onClick={() => onTableHead(header)}>
              <Stack direction='row' alignItems='center'>
                {header}
                {sortBy == header && (ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
              </Stack>
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {campaigns
            .sorted((a, b) => sortTable(a, b))
            .map(campaign => (
              <TableRow key={campaign.campaignId + campaign.brandId}>
                <TableCell align='left'>
                  <Grid container alignItems='center'>
                    {campaign.videoType && campaign.videoType == 'premium' && (
                      <Tooltip title='premium'>
                        <StarIcon />
                      </Tooltip>
                    )}
                    <Box
                      component='img'
                      src={campaign.brandLogo}
                      alt='influencerpic'
                      sx={{ maxWidth: '4em', maxHeight: '3em', width: 'auto', height: 'auto', paddingRight: '5px' }}
                    />{' '}
                    <Box component='a' target='_blank' rel='noopener noreferrer' href={`${process.env.REACT_APP_WEB_URL}/${campaign.brandId}/dashboard`}>
                      {campaign.brandName}
                    </Box>
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Grid container alignItems='center'>
                    <Box
                      component='img'
                      src={campaign.campaignImage}
                      alt='influencerpic'
                      sx={{ maxWidth: '4em', maxHeight: '3em', width: 'auto', height: 'auto', paddingRight: '5px' }}
                    />{' '}
                    <Box
                      component='a'
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`${process.env.REACT_APP_WEB_URL}/content/${campaign.brandId}/${campaign.campaignId}/applications`}>
                      {campaign.productInfo?.name}
                    </Box>
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Grid container direction='column' alignItems='center'>
                    <PersonAdd />
                    {campaign.applied}
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Grid container direction='column' alignItems='center'>
                    <Person />
                    {campaign.accepted}
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Grid container direction='column' alignItems='center'>
                    <Equalizer />
                    {campaign.numberOfSlots}
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Grid container direction='column' alignItems='center'>
                    <Redeem />
                    {campaign.locale}
                  </Grid>
                </TableCell>
                <TableCell align='left'>
                  <Grid container direction='column' alignItems='center'>
                    {!!campaign.isVerifiedTimestamp && moment(campaign.isVerifiedTimestamp).format('DD.MM.YYYY')}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Tooltip title='Offline nehmen' sx={{ display: 'inline-block', mt: '-16px', mr: 1 }}>
                    <IconButton
                      onClick={() => {
                        campaignService.setCampaignOffline({ brandId: campaign.brandId, campaignId: campaign.campaignId })
                        setCampaigns(prev => prev.filter(c => c.campaignId != campaign.campaignId))
                      }}>
                      <CloudOffIcon />
                    </IconButton>
                  </Tooltip>
                  <Box sx={{ display: 'inline-block' }}>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => handleOpen(open === campaign.campaignId ? false : campaign.campaignId)}>
                      <CommentIcon color={open === campaign.campaignId ? 'info' : undefined} />
                    </Box>
                    <CommentDialog open={open} onClose={() => handleOpen(false)} id={campaign.campaignId} campaignId={campaign.campaignId} comments={campaign.comments} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OnlineTable
