import { Stack, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { Comment } from '../../types/types'
import { CommentItem } from './CommentItem'

const CommentsList = (props: { comments: Comment[]; height?: string }) => {
  const listDiv = useRef<HTMLDivElement>(null)
  const { comments, height = '400px' } = props

  const scrollToLastMessage = () => {
    const count = listDiv.current?.children.length ?? 0
    const last = listDiv.current?.children.item(count - 1)
    if (last) last.scrollIntoView({ block: 'start', inline: 'end', behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToLastMessage()
  }, [comments])

  return (
    <Stack ref={listDiv} height={height} width='100%' overflow='scroll' spacing={2} sx={{ pr: 5, overflowX: 'auto' }}>
      {comments.length === 0 && <Typography variant='subtitle1'>There are no comments yet!</Typography>}
      {comments.map(comment => (
        <CommentItem key={comment.commentId} comment={comment} />
      ))}
    </Stack>
  )
}

export default CommentsList
