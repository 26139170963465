import { CheckCircle, Euro } from '@mui/icons-material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import StarIcon from '@mui/icons-material/Star'
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import campaignService from '../../service/CampaignService'
import OfflineBody from './tableBodies/OfflineBody'
import OnlineTable from './tableBodies/OnlineTable'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'
import { Campaign, Service } from '../../types/types'
import { Comment as CommentIcon } from '@mui/icons-material'
import CommentDialog from '../comments/CommentDialog'

enum TableType {
  AWAITING_RELEASE,
  ONLINE,
  OFFLINE
}

// Sorting Campaigns
export enum TableHeaderTypes {
  NAME = 'Name',
  TITLE = 'Titel',
  APPLICANTS = 'Bewerber',
  PARTICIPANTS = 'Teilnehmer',
  DENIED_APPLICANTS = 'abgelehnte Bewerber',
  CAMPAIGN_LOCALE = 'Art',
  SLOTS = 'Number of Slots',
  VERIFIED_DATE = 'Freigabedatum',
  ACTIONS = 'Aktionen'
}

const CampaignsTable = () => {
  const [campaigns, setCampaigns] = useState([] as Campaign[])
  const [loading, setLoading] = useState(true)
  const [currentTable, setCurrentTable] = useState(TableType.AWAITING_RELEASE)
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [deletionLoading, setDeletionLoading] = useState(false)
  const [sortBy, setSortBy] = useState(TableHeaderTypes.VERIFIED_DATE)
  const [ascending, setAscending] = useState(false)
  const [notifyInfluencer, setNotifyInfluencer] = useState(false)
  const [currentCampaign, setCurrentCampaign] = useState<undefined | Campaign>(undefined)
  const [showNotifyModal, setShowNotifyModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [searchField, setSearchField] = useState('')
  const [activeOnly, setActiveOnly] = useState(false)
  const [open, setOpen] = useState<string | false>(false)

  const { enqueueSnackbar } = useSnackbar()

  async function fetchCampaigns(callback: React.Dispatch<React.SetStateAction<boolean>>) {
    if (currentTable == TableType.ONLINE) return // Loading is handled in OnlineBody
    try {
      const campaigns = await campaignService.getCampaigns(currentTable)
      console.log(campaigns)
      setCampaigns(campaigns)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
      callback(false)
    }
  }

  useEffect(() => {
    if (campaigns.length === 0 && loading) {
      fetchCampaigns(setLoading)
    }
  }, [campaigns, loading])

  const loadMore = async () => {
    if (currentTable == TableType.ONLINE) return // Loading is handled in OnlineBody
    try {
      if (!!campaigns.length && !loading) {
        const newCampaigns = await campaignService.getCampaigns(currentTable)
        setCampaigns(prev => [...(prev ?? []), ...newCampaigns])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleOpen = (value: string | false) => setOpen(value)

  const removeCampaignFromArray = (campaignId: string, brandId: string) => {
    const array = [...(campaigns ?? [])]
    const index = array.findIndex(c => c.campaignId === campaignId && c.brandId === brandId)

    if (index !== -1) {
      array.splice(index, 1)
      setCampaigns(array)
    }
  }

  const onVerifyCampaign = async (campaign: Campaign) => {
    const { campaignId, brandId } = campaign

    try {
      setVerifyLoading(true)

      await campaignService.verifyCampaign(campaignId, brandId, notifyInfluencer)
      setNotifyInfluencer(false)

      const newCampaign = campaign
      if (newCampaign) newCampaign.isVerified = true
      removeCampaignFromArray(newCampaign.campaignId, newCampaign.brandId)

      setShowNotifyModal(false)
      enqueueSnackbar(`Kampagne von ${campaign.brandName} freigegeben`, { variant: 'success' })
    } catch (e) {
      console.log(e)
      alert(e)
    } finally {
      setVerifyLoading(false)
    }
  }

  const onDeleteCampaign = async () => {
    if (!currentCampaign) return
    const { campaignId, brandId } = currentCampaign

    try {
      setDeletionLoading(true)
      await campaignService.deleteCampaign({ campaignId, brandId })
      removeCampaignFromArray(campaignId, brandId)
    } catch (e) {
      enqueueSnackbar(`Fehler bei Löschen`, { variant: 'error' })
      console.error(e)
    } finally {
      setDeletionLoading(false)
      setCurrentCampaign(undefined)
      setShowDeleteModal(false)
    }
  }

  const sortTable = (a: Campaign, b: Campaign) => {
    if (ascending) {
      switch (sortBy) {
        case TableHeaderTypes.NAME:
          return ('' + a.brandName).localeCompare(b.brandName)
        case TableHeaderTypes.TITLE:
          return ('' + a.title).localeCompare(b.title)
        case TableHeaderTypes.APPLICANTS:
          return a.applicants - b.applicants
        case TableHeaderTypes.PARTICIPANTS:
          return a.participants - b.participants
        case TableHeaderTypes.DENIED_APPLICANTS:
          return a.deniedApplicants - b.deniedApplicants
        default:
          return 0
      }
    } else {
      switch (sortBy) {
        case TableHeaderTypes.NAME:
          return ('' + b.brandName).localeCompare(a.brandName)
        case TableHeaderTypes.TITLE:
          return ('' + b.title).localeCompare(a.title)
        case TableHeaderTypes.APPLICANTS:
          return b.applicants - a.applicants
        case TableHeaderTypes.PARTICIPANTS:
          return b.participants - a.participants
        case TableHeaderTypes.DENIED_APPLICANTS:
          return b.deniedApplicants - a.deniedApplicants
        default:
          return 0
      }
    }
  }

  const onTableHead = (type: TableHeaderTypes) => {
    if (type === sortBy) setAscending(!ascending)

    if (type !== sortBy) setSortBy(type)
  }

  const getPassedTime = (time: number) => {
    let passedMs = Date.now() - time
    const days = Math.floor(passedMs / (86400 * 1000))
    passedMs -= days * (86400 * 1000)
    const hours = Math.floor(passedMs / (60 * 60 * 1000))
    return `${days} Tage ${hours}h`
  }

  const NotifyModal = () => (
    <Dialog open={showNotifyModal} onClose={() => setShowNotifyModal(false)} aria-labelledby='form-dialog-title' fullWidth>
      <DialogTitle id='form-dialog-title'>Notification</DialogTitle>
      <DialogContent>
        <Checkbox value={notifyInfluencer} onChange={() => setNotifyInfluencer(!notifyInfluencer)} />
        {`geeignete Influencer über neue Kampagne "${currentCampaign?.brandName}" benachrichtigen`}
        <DialogActions>
          <LoadingButton
            variant='contained'
            loading={verifyLoading}
            onClick={() => {
              if (currentCampaign) onVerifyCampaign(currentCampaign)
            }}>
            Freigeben{' '}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )

  const DeleteModal = () => (
    <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)} fullWidth>
      <DialogTitle id='form-dialog-title'>Löschen</DialogTitle>
      <DialogContent>
        Bist du dir sicher die Kampagne zu löschen? (Diese Aktion ist nicht permanent)
        <DialogActions>
          <LoadingButton
            variant='contained'
            loading={deletionLoading}
            onClick={() => {
              if (currentCampaign) onDeleteCampaign()
            }}>
            Löschen
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )

  const openNotifyModal = (campaign: Campaign) => {
    setNotifyInfluencer(false)
    setCurrentCampaign(campaign)
    setShowNotifyModal(true)
  }

  const openDeleteModal = (campaign: Campaign) => {
    setCurrentCampaign(campaign)
    setShowDeleteModal(true)
  }

  const ReleaseBody = () => (
    <TableBody>
      {campaigns
        .sort((a, b) => b.createdAt - a.createdAt)
        .map(campaign => (
          <TableRow key={campaign.campaignId + campaign.brandId}>
            <TableCell align='left'>
              <Grid container alignItems='center'>
                {campaign.videoType && campaign.videoType == 'premium' && (
                  <Tooltip title='premium'>
                    <StarIcon />
                  </Tooltip>
                )}
                <Box component='img' src={campaign.brandLogo} alt='logo' sx={{ maxWidth: '4em', maxHeight: '3em', width: 'auto', height: 'auto', paddingRight: '5px' }} />

                {campaign.brandName}
              </Grid>
            </TableCell>
            <TableCell align='left'>
              <Grid container alignItems='center'>
                <Box
                  component='img'
                  src={campaign.campaignImage}
                  alt='influencerpic'
                  sx={{ maxWidth: '4em', maxHeight: '3em', width: 'auto', height: 'auto', paddingRight: '5px' }}
                />{' '}
                <a target='_blank' rel='noopener noreferrer' href={`${process.env.REACT_APP_WEB_URL}/form/${campaign.brandId}/${campaign.campaignId}/view`}>
                  {campaign.title}
                </a>
              </Grid>
            </TableCell>
            <TableCell>{getPassedTime(campaign.createdAt)}</TableCell>
            <TableCell>{campaign.service && campaign.service === Service.nanoService ? 'getnano Service gebucht' : 'Kein Service gebucht'}</TableCell>
            <TableCell>
              {!campaign.isVerified && (
                <div onClick={() => openNotifyModal(campaign)}>
                  <CheckCircle />
                </div>
              )}
            </TableCell>
            <TableCell>
              <Grid container alignItems='center' spacing={1}>
                <Grid item xs={3}>
                  <div onClick={() => openDeleteModal(campaign)} style={{ display: 'flex', alignItems: 'center' }}>
                    <DeleteIcon />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    onClick={() => handleOpen(open === campaign.campaignId ? false : campaign.campaignId)}>
                    <CommentIcon color={open === campaign.campaignId ? 'info' : undefined} />
                  </Box>
                  {open === campaign.campaignId && (
                    <CommentDialog open={open} onClose={() => handleOpen(false)} id={campaign.campaignId} campaignId={campaign.campaignId} comments={campaign.comments} />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Tooltip title={campaign.isPayed ? 'Wurde bezahlt' : 'Zahlung Auständig'} sx={{ mt: '-10px', ml: '-3px' }}>
                    <a target='_blank' rel='noopener noreferrer' href={campaign.invoiceLink} style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <Euro style={{ color: campaign.isPayed ? 'green' : 'red' }} />
                      </IconButton>
                    </a>
                  </Tooltip>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  )

  const makeHead = (type: TableType) => {
    switch (type) {
      case TableType.OFFLINE: {
        const headers: string[] = [
          TableHeaderTypes.NAME,
          TableHeaderTypes.TITLE,
          TableHeaderTypes.APPLICANTS,
          TableHeaderTypes.PARTICIPANTS,
          TableHeaderTypes.DENIED_APPLICANTS,
          TableHeaderTypes.CAMPAIGN_LOCALE,
          TableHeaderTypes.ACTIONS
        ]
        const types = Object.values(TableHeaderTypes) as string[]
        return (
          <TableHead>
            {headers.map(name => {
              const isSortable = types.includes(name)
              if (isSortable) {
                const type = name as TableHeaderTypes
                return (
                  <TableCell onClick={() => onTableHead(type)} id={name} key={name}>
                    {type}
                    {sortBy == type && (ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                  </TableCell>
                )
              } else {
                return (
                  <TableCell key={name} id={name}>
                    {name}
                  </TableCell>
                )
              }
            })}
          </TableHead>
        )
      }
    }
    if (type === TableType.AWAITING_RELEASE) {
      const cells = ['Name', 'Titel', 'Wartezeit', 'getnano Service', 'Freigabe', 'Ausblenden']

      return (
        <TableHead>
          {cells.map(c => (
            <TableCell key={c}>{c}</TableCell>
          ))}
        </TableHead>
      )
    }

    return <></>
  }

  const makeBody = (type: TableType) => {
    if (loading) {
      return (
        <TableBody>
          <Grid justifyContent='center'>
            <CircularProgress />
          </Grid>
        </TableBody>
      )
    }
    switch (type) {
      case TableType.ONLINE:
        return <OnlineTable />
      case TableType.AWAITING_RELEASE:
        return <ReleaseBody />
      case TableType.OFFLINE:
        return (
          <OfflineBody
            open={open}
            onChangeOpen={handleOpen}
            campaigns={[...campaigns.sort(sortTable)]}
            loadMore={loadMore}
            refetchComments={async () => await fetchCampaigns(setLoading)}
          />
        )
      default:
        return <></>
    }
  }

  const handleChangeCurrentTable = (_: React.SyntheticEvent, value: TableType) => {
    switch (value) {
      case TableType.ONLINE:
        console.log('Online')
        setCurrentTable(TableType.ONLINE)
        setCampaigns([])
        setLoading(true)
        break
      case TableType.AWAITING_RELEASE:
        console.log('Awaiting')
        setCurrentTable(TableType.AWAITING_RELEASE)
        setCampaigns([])
        setLoading(true)
        break
      case TableType.OFFLINE:
        console.log('Offline')
        setCurrentTable(TableType.OFFLINE)
        setCampaigns([])
        setLoading(true)
        break
      default:
        console.log('default')
        setCurrentTable(TableType.ONLINE)
        setCampaigns([])
        setLoading(true)
        break
    }
  }

  const handleSearch = async () => {
    try {
      setLoading(true)
      const campaigns = await campaignService.searchCampaign({ searchTerm: searchField, activeOnly })
      setCampaigns(campaigns)
      if (campaigns.length == 0) enqueueSnackbar(`Keine Kampagnen gefunden`, { variant: 'info' })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const renderSearchBar = () => {
    return (
      <Grid container item xs={12} p={2} alignItems='center'>
        <Grid item xs={12} md={6}>
          <TextField
            value={searchField}
            label='Kampagnen Titel oder Brandname'
            onKeyPress={ev => ev.key == 'Enter' && handleSearch()}
            onChange={e => setSearchField(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2} pl={1}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={activeOnly} onChange={e => setActiveOnly(e.target?.checked ?? false)} />} label='Nur Aktive' />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={4} pl={1}>
          <LoadingButton variant='contained' loading={loading} onClick={handleSearch}>
            Suchen
          </LoadingButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid container justifyContent='center' spacing={2}>
        {renderSearchBar()}
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTable} onChange={handleChangeCurrentTable}>
              <Tab label='Freigabe' value={TableType.AWAITING_RELEASE} />
              <Tab label='Online' value={TableType.ONLINE} />
              <Tab label='Offline' value={TableType.OFFLINE} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <TableContainer component={Paper}>
              {currentTable == TableType.ONLINE ? (
                <OnlineTable />
              ) : (
                <Table>
                  {makeHead(currentTable)}
                  {makeBody(currentTable)}
                </Table>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      {NotifyModal()}
      {DeleteModal()}
    </>
  )
}

export default CampaignsTable
