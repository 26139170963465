import React, { useState } from 'react'
import { IconButton, Dialog, DialogContent } from '@mui/material'
import UploadIcon from '@mui/icons-material/CloudUpload'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

interface ImageVideoUploadProps {
  value: string
  onUpload: (file: File) => void
  type: 'image' | 'video'
  editable: boolean
}

const ImageVideoUpload: React.FC<ImageVideoUploadProps> = ({ value, onUpload, type, editable }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('File change detected') // Debugging log
    const file = event.target.files?.[0]
    if (file) {
      console.log('Selected file:', file) // Debugging log
      onUpload(file)
    }
  }

  const handlePlayButtonClick = () => setDialogOpen(true)

  const handleDialogClose = () => setDialogOpen(false)

  if (!value) return <></>

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ position: 'relative', width: '100%', height: '100%', textAlign: 'center' }}>
      {type === 'image' ? (
        <img src={value} alt='' style={{ height: '100%', objectFit: 'cover' }} />
      ) : (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <IconButton
            onClick={handlePlayButtonClick}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white'
            }}>
            <PlayArrowIcon />
          </IconButton>
        </div>
      )}
      {editable && (
        <IconButton
          component='label'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: isHovered ? 'block' : 'none'
          }}>
          <UploadIcon />
          <input type='file' hidden onChange={handleFileChange} />
        </IconButton>
      )}
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth='md'>
          <DialogContent>
            <video src={value} controls style={{ maxWidth: '400px' }} autoPlay />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default ImageVideoUpload
