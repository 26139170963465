import { GridRowsProp } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import PersistedDataGrid, { CustomGridColDef } from '../../components/dataGrid/PersistedDataGrid'
import InfluencerService from '../../service/InfluencerService'
import { useLocation } from 'react-router-dom'
import { Button } from '@mui/material'

export const InvoiceList = () => {
  const [rows, setRows] = useState<GridRowsProp | undefined>(undefined)
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const influencerId = queryParams.get('influencerId')

  useEffect(() => {
    const fetchInvoices = async () => {
      if (influencerId) {
        const invoices = await InfluencerService.getInvoices({ influencerId })
        setRows(
          invoices.map(i => ({
            id: i.creditHistoryId,
            ...i,
            createdAt: new Date(i.createdAt),
            createdAtTranaction: i.createdAtTransaction ? new Date(i.createdAtTransaction) : undefined,
            createdAtInvoice: i.createdAtInvoice ? new Date(i.createdAtInvoice) : undefined
          })) as GridRowsProp
        )
      }
    }

    fetchInvoices()
  }, [influencerId])

  const invoiceColoumns: CustomGridColDef[] = [
    { field: 'influencerId', headerName: 'Influencer ID', width: 150, aggregable: false },
    { field: 'brandName', headerName: 'Brandname', width: 150, aggregable: false },
    { field: 'amount', headerName: 'Betrag', width: 150, groupable: false, type: 'number' },
    { field: 'amountBefore', headerName: 'Betrag (davor)', width: 150, groupable: false, type: 'number' },
    { field: 'amountAfter', headerName: 'Betrag (danach)', width: 150, groupable: false, type: 'number' },
    { field: 'invoiceNumber', headerName: 'Gutschrift Nummer', width: 150, aggregable: false },
    {
      field: 'invoiceLink',
      headerName: 'Link zur Gutschrift',
      width: 250,
      aggregable: false,
      renderCell: params =>
        params.row.invoiceLink &&
        params.row.id && (
          <Button variant='contained' color='primary' onClick={() => window.open(params.row.invoiceLink, '_blank')}>
            Gutschrift Anzeigen
          </Button>
        )
    },
    { field: 'type', headerName: 'Guthaben Grund', width: 250, aggregable: false },
    { field: 'createdAt', headerName: 'Erhaltdatum (Guthaben)', width: 200, type: 'date', aggregable: false },
    { field: 'createdAtTranaction', headerName: 'Erstellungsdatum (Anfrage)', width: 200, type: 'date', aggregable: false },
    { field: 'createdAtInvoice', headerName: 'Erstellungsdatum (Gutschrift)', width: 200, type: 'date', aggregable: false },
    { field: 'transactionId', headerName: 'Zugehörige Transaction ID', width: 100, aggregable: false, groupable: false },
    { field: 'creditHistoryId', headerName: 'Zugehörige Guthaben ID', width: 100, aggregable: false }
  ]

  return <PersistedDataGrid rows={rows} columns={invoiceColoumns} />
}
