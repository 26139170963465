import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { LoadingButton } from '@mui/lab'
import { useChat } from '../../context/ChatContext'
import agent from '../../agent'

export const CreateConversationView = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { influencerId: influencerIdFromPath } = useParams<{ influencerId?: string }>()
  const { conversations, setConversations, setSelectedConvo } = useChat()
  const [influencerId, setInfluencerId] = useState(influencerIdFromPath ?? '')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const createConversation = async () => {
    setLoading(true)
    try {
      const existingConvo = conversations.find(c => c.influencerId == influencerId)
      if (existingConvo) {
        setSelectedConvo(existingConvo)
      } else {
        const newConvo = await agent.Chat.createConversation({ influencerId })
        setConversations([...conversations, newConvo])
        setSelectedConvo(newConvo)
        enqueueSnackbar('Created conversation', { variant: 'success' })
      }
      setOpen(false)
    } catch (e) {
      enqueueSnackbar('Failed to create conversation', { variant: 'error' })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (influencerIdFromPath) {
      createConversation()
      setOpen(true)
    }
  }, [influencerIdFromPath])

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create conversation</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Write influencer's/brand's id, so you can chat with them`}</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='User Id'
            type='text'
            fullWidth
            variant='standard'
            value={influencerId}
            onChange={e => setInfluencerId(e.target?.value ?? '')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton variant='contained' onClick={createConversation} loading={loading} disabled={!influencerId}>
            Create conversation
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Create conversation
      </Button>
    </>
  )
}
