import { Button, Checkbox, CircularProgress, Grid, IconButton, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React, { useEffect, useState } from 'react'
import transactionService from '../../service/TransactionService'
import { getFormattedDate } from '../../util'
import CheckIcon from '@mui/icons-material/Check'
import { Info } from '@mui/icons-material'
import TransactionDetailsDialog from './TransactionDetailsDialog'
import { CSVLink } from 'react-csv'
import { TransactionDetail } from '../../types/types'
import JSZip from 'jszip'
import moment from 'moment'

type Transaction = Awaited<ReturnType<typeof transactionService.getTransactions>>[number]
enum InfluencerTransactionType {
  TRANSACTION_REQUESTED = 'TRANSACTION_REQUESTED',
  TRANSACTION_FULFILLED = 'TRANSACTION_FULFILLED'
}

// Sorting Transactions
export enum TableHeaderTypes {
  ID = 'Id',
  NAME = 'Name',
  BILLING_NAME = 'Konto Name',
  AMOUNT = 'Betrag',
  CREATED_AT = 'Erstell Datum',
  IBAN = 'IBAN',
  VAT = 'UID',
  IS_ENTREPRENEUR = 'Selbstst.',
  ADDRESS = 'Adresse',
  INVOICE_LINK = 'Link zur Gutschrift'
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Transactions = () => {
  const [transactions, setTransactions] = useState<Transaction[] | undefined>(undefined)
  const [transactionDetail, setTransactionDetail] = useState<TransactionDetail | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [loadingRequested, setLoadingRequested] = useState(false)
  const [loadingFulfilled, setLoadingFulfilled] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [selected, setSelected] = React.useState([] as string[])
  const [isFulfilled, setIsFulfilled] = useState(false)
  const [successMessage, setSuccessMessage] = React.useState('' as string)
  const [errorMessage, setErrorMessage] = React.useState('' as string)

  async function fetchTransactions(callback: React.Dispatch<React.SetStateAction<boolean>>) {
    try {
      setLoading(true)
      const transactions = await transactionService.getTransactions(isFulfilled)
      console.log(transactions)
      setTransactions(transactions)
    } catch (e) {
      setError(true)
      console.error(e)
    } finally {
      callback(false)
    }
  }

  useEffect(() => {
    if (transactions === undefined && !error) {
      fetchTransactions(setLoading)
    }
  }, [transactions, loading])

  const selectNone = () => setSelected([])
  async function createInvoice(): Promise<void> {
    try {
      let errorMessage = 'Gutschriften für folgende Personen konnten nicht erstellt werden \n'
      setLoadingRequested(true)
      const generatedInvoices = await transactionService.generateInvoices(selected)
      if (!generatedInvoices.failed.length && !generatedInvoices.worked.length) {
        setErrorMessage('Es ist etwas schiefgelaufen')
      } else {
        if (transactions) {
          transactions.map(t => {
            const newTransaction = generatedInvoices.worked.find(ct => t.transactionId === ct.transactionId)
            if (newTransaction) {
              t.invoiceLink = newTransaction.invoiceLink
            }
            const failedTransaction = generatedInvoices.failed.find(ct => t.transactionId === ct.transactionId)
            if (failedTransaction) {
              errorMessage += '' + t.first_name + ' ' + t.last_name + '\n'
            }
          })
        }
        if (errorMessage === 'Gutschriften für folgende Personen konnten nicht erstellt werden \n') {
          setErrorMessage('')
          setSuccessMessage('Gutschriften erfolgreich erstellt')
        } else {
          setErrorMessage(errorMessage)
        }
        selectNone()
        await fetchTransactions(setLoading)
      }
    } catch (e) {
      console.error(e)
      setSuccessMessage('')
      setErrorMessage('Es ist etwas schiefgelaufen')
    } finally {
      setLoadingRequested(false)
    }
  }

  const normalizeCSVData = (data: string) => {
    // field names and restrictions by qonto, see https://help.qonto.com/de/articles/4359550-wie-erstelle-ich-eine-sammeluberweisung-mit-einer-csv-datei
    // replace accented chars (normalizing decomposes combined graphemes, then regex character class matching removes the diacritics)
    return data
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .substring(0, 140)
  }
  const getCSVData = () => {
    if (!transactions) return []

    const csvData = [['beneficiary_name', 'iban', 'amount', 'currency', 'reference']]

    const filteredTransactions = transactions.filter(t => selected.includes(t.transactionId)).filter(t => t.invoiceNumber)

    for (const t of filteredTransactions) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      csvData.push([`${normalizeCSVData(`${t.first_name} ${t.last_name}`)}`, t.iban, '' + (t.amountTotal / 100).toFixed(2), 'EUR', normalizeCSVData(t.invoiceNumber!)])
    }

    return csvData
  }

  async function downloadTransactions(): Promise<void> {
    setLoadingDownload(true)
    try {
      const selectedWithInvoiceLinks = transactions && transactions?.filter(t => selected.includes(t.transactionId)).filter(t => t.invoiceLink)
      console.log(selectedWithInvoiceLinks)
      if (selected && selectedWithInvoiceLinks) {
        const zip = new JSZip()
        await Promise.all(
          selectedWithInvoiceLinks.map(async t => {
            if (t && t.invoiceLink) {
              const response = await fetch(t.invoiceLink)
              const blob = await response.blob()
              // Add files to zip
              zip.file('nano_' + t.invoiceNumber + '.pdf', blob)
            }
          })
        )

        const zipData = await zip.generateAsync({
          type: 'blob',
          streamFiles: true
        })

        console.log(zipData)
        // Create download link for zip
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(zipData)
        link.download = 'nano_pdf_invoices_' + moment().format('YYYY_MM_DD') + '.zip'
        link.click()
      }
      if (errorMessage === 'Gutschriften für folgende Personen konnten nicht erstellt werden \n') {
        setErrorMessage('')
        setSuccessMessage('Gutschriften erfolgreich erstellt')
      } else {
        setErrorMessage(errorMessage)
      }
      selectNone()
    } catch (e) {
      setSuccessMessage('')
      setErrorMessage('Es ist etwas schiefgelaufen')
    } finally {
      setLoadingDownload(false)
    }
  }

  async function sendTransactions(): Promise<void> {
    try {
      let errorMessage = 'Folgende Überwesungen konnten nicht bestätigt werden \n'
      setLoadingFulfilled(true)
      const fulfilledTransactions = await transactionService.markAsSent(selected)
      if (transactions) {
        if (transactions) {
          setTransactions(transactions.filter(t => !selected.includes(t.transactionId)))
        }
        transactions.map(t => {
          const failedTransaction = fulfilledTransactions.failed.find(ct => t.transactionId === ct.transactionId)
          if (failedTransaction) {
            errorMessage += '' + t.first_name + ' ' + t.last_name + '\n'
          }
        })
      }
      if (errorMessage === 'Folgende Überwesungen konnten nicht bestätigt werden \n') {
        setErrorMessage('')
        setSuccessMessage('Alle als überwiesen markiert')
      } else {
        setErrorMessage(errorMessage)
      }
      selectNone()
    } catch (e) {
      setSuccessMessage('')
      setErrorMessage('Es ist etwas schiefgelaufen')
      console.error(e)
    } finally {
      setLoadingFulfilled(false)
    }
  }
  const handleRowClick = (id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1
  const isAllSelected = () => selected.length === transactions?.length
  const invoiceSelected = (): boolean => {
    let check = true
    if (transactions) {
      for (const s of selected) {
        for (const t of transactions) {
          if (t.transactionId === s && !t.invoiceLink) {
            check = false
          }
        }
      }
    }
    return check
  }
  const noInvoiceSelected = (): boolean => {
    let check = true
    if (transactions) {
      for (const s of selected) {
        for (const t of transactions) {
          if (t.transactionId === s && t.invoiceLink) {
            check = false
          }
        }
      }
    }
    return check
  }
  const handleSelectAll = () => {
    if (transactions && selected.length !== transactions.length) {
      setSelected(transactions.map(t => t.transactionId))
    } else {
      setSelected([])
    }
  }

  const handleCloseError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setErrorMessage('')
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccessMessage('')
  }
  const makeHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding='checkbox' onClick={() => handleSelectAll()}>
            <Checkbox
              color='primary'
              checked={isAllSelected()}
              inputProps={{
                'aria-labelledby': 'controlled'
              }}
            />
          </TableCell>
          <TableCell>{TableHeaderTypes.ID}</TableCell>
          <TableCell>{TableHeaderTypes.NAME}</TableCell>
          <TableCell>{TableHeaderTypes.BILLING_NAME}</TableCell>
          <TableCell>{TableHeaderTypes.ADDRESS}</TableCell>
          <TableCell>{TableHeaderTypes.AMOUNT}</TableCell>
          <TableCell>{TableHeaderTypes.CREATED_AT}</TableCell>
          <TableCell>{TableHeaderTypes.IBAN}</TableCell>
          <TableCell>{TableHeaderTypes.IS_ENTREPRENEUR}</TableCell>
          <TableCell>{TableHeaderTypes.VAT}</TableCell>
          <TableCell>{TableHeaderTypes.INVOICE_LINK}</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const makeBody = () => {
    if (error) {
      return <Grid justifyContent='center'>Fehler aufgetreten</Grid>
    }
    if (loading) {
      return (
        <TableBody>
          <Grid justifyContent='center'>
            <CircularProgress />
          </Grid>
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TransactionDetailsDialog details={transactionDetail} onClose={() => setTransactionDetail(undefined)} />
          {transactions &&
            transactions
              .sort((a, b) => b.createdAt - a.createdAt)
              .map(transaction => (
                <TableRow
                  key={transaction.transactionId}
                  selected={isSelected(transaction.transactionId)}
                  onClick={() => handleRowClick(transaction.transactionId)}
                  role='checkbox'
                  aria-checked={isSelected(transaction.transactionId)}
                  tabIndex={-1}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isSelected(transaction.transactionId)}
                      inputProps={{
                        'aria-labelledby': transaction.transactionId
                      }}
                    />
                  </TableCell>
                  <TableCell align='left'>
                    {transaction.transactionId}
                    <IconButton onClick={() => setTransactionDetail(transaction.details)}>
                      <Info />
                    </IconButton>
                  </TableCell>
                  <TableCell align='left'>
                    {transaction.first_name} {transaction.last_name}
                  </TableCell>
                  <TableCell align='left'>
                    {transaction.first_name} {transaction.last_name}
                  </TableCell>
                  <TableCell align='left'>
                    {transaction.address}, {transaction.zip}, {transaction.city} {transaction.country}
                  </TableCell>
                  <TableCell align='left'>€ {transaction.amountTotal / 100}</TableCell>
                  <TableCell align='left'>{getFormattedDate(transaction.createdAt)}</TableCell>
                  <TableCell align='left'>{transaction.iban}</TableCell>
                  <TableCell align='left'>{transaction.isEntrepreneur && <CheckIcon />}</TableCell>
                  <TableCell align='left'>{transaction.vat_id}</TableCell>
                  <TableCell
                    align='left'
                    style={{
                      cursor: transaction.invoiceLink ? 'pointer' : 'auto',
                      fontWeight: transaction.invoiceLink ? 600 : 300,
                      textDecoration: transaction.invoiceLink ? 'underline' : 'none'
                    }}
                    onClick={() => {
                      if (transaction.invoiceLink) window.open(transaction.invoiceLink, '_blank', 'noopener,noreferrer')
                    }}>
                    {transaction.invoiceLink ? 'Link' : 'nicht vorhanden'}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      )
    }
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    switch (value) {
      case InfluencerTransactionType.TRANSACTION_REQUESTED:
        setIsFulfilled(false)
        setTransactions(undefined)
        setSelected([])
        break
      case InfluencerTransactionType.TRANSACTION_FULFILLED:
        setIsFulfilled(true)
        setTransactions(undefined)
        setSelected([])
        break
      default:
        setIsFulfilled(false)
        setTransactions(undefined)
        setSelected([])
        break
    }
  }
  return (
    <Grid container justifyContent='center' style={{ marginTop: '2em' }}>
      <Snackbar open={successMessage ? true : false} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorMessage ? true : false} autoHideDuration={5000}>
        <Alert onClose={handleCloseError} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={4}>
        <Radio checked={!isFulfilled} onChange={handleRadioChange} value={InfluencerTransactionType.TRANSACTION_REQUESTED} name='Angefragt' />
        Angefragt
        <Radio checked={isFulfilled} onChange={handleRadioChange} value={InfluencerTransactionType.TRANSACTION_FULFILLED} name='Überwiesen' />
        Überwiesen
      </Grid>
      <Grid
        item
        xs={8}
        style={{
          visibility: !isFulfilled ? 'visible' : 'hidden'
        }}>
        <Grid container justifyContent='space-around' spacing={2}>
          <Button variant='contained' onClick={() => createInvoice()} disabled={loadingRequested || selected.length === 0 || invoiceSelected()}>
            {loadingRequested ? 'Laden...' : 'Gutschriften erstellen'}
          </Button>
          <Button variant='contained' disabled={selected.length === 0 || noInvoiceSelected()}>
            <CSVLink data={getCSVData()} enclosingCharacter={''}>
              CSV herunterladen
            </CSVLink>
          </Button>
          <Button variant='contained' onClick={() => downloadTransactions()} disabled={selected.length === 0 || noInvoiceSelected()}>
            {loadingDownload ? 'Laden...' : 'Überweisungen herunterladen'}
          </Button>
          <Button variant='contained' onClick={() => sendTransactions()} disabled={loadingFulfilled || selected.length === 0 || noInvoiceSelected()}>
            {loadingFulfilled ? 'Laden...' : 'Überweisungen bestätigen'}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <TableContainer component={Paper}>
            <Table>
              {makeHead()}
              {makeBody()}
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Transactions
