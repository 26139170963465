import { Box, Grid, Link, Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { closeSnackbar, useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import campaignService from '../../service/CampaignService'
import { uploadToS3 } from '../../util'
import { InputFile } from '../common/InputFile'

type Content = Awaited<ReturnType<typeof campaignService.getAllUneditedContent>>[number]

const VideoItem = ({ content, remove }: { content: Content; remove(): void }) => {
  const { brandName, contentLinks, influencerFirstname, videoFeature, daysPassed, brandId, influencerId, campaignId } = content
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const upload = async (file: File) => {
    setIsLoading(true)
    const key = enqueueSnackbar(`Uploading: ${file.name}`, { variant: 'info', persist: true })
    try {
      const url = (await campaignService.getEditedMediaUploadUrl({ brandId, influencerId, campaignId, videoFeature })).url
      await uploadToS3(url, file)
      await campaignService.sendEditedContent({ ...content, editedContentLink: url })
      remove()
      enqueueSnackbar(`Successful upload: ${file.name}`, { variant: 'success' })
    } catch (e) {
      console.error(e)
      enqueueSnackbar(`Upload failed: ${file.name}`, { variant: 'error' })
    } finally {
      closeSnackbar(key)
      setIsLoading(false)
    }
  }
  return (
    <>
      <InputFile open={open} setOpen={setOpen} onFile={file => upload(file)} />
      <TableCell component='th' scope='row'>
        {brandName}
      </TableCell>
      <TableCell align='right'>
        <Grid container alignItems='center'>
          {influencerFirstname}
        </Grid>
      </TableCell>
      <TableCell>{`Video Features: ${videoFeature}`}</TableCell>
      <TableCell>Im Check seid: {daysPassed}</TableCell>
      <TableCell align='center'>
        <Stack>
          <Link href={contentLinks.find(c => !c.videoFeature)?.link} target='_blank' variant='h6'>
            Download raw content
          </Link>
          <Link component='button' variant='h6' onClick={() => setOpen(true)} disabled={isLoading}>
            Upload edited content
          </Link>
        </Stack>
      </TableCell>
    </>
  )
}

export const VideoEditing = () => {
  const [contents, setContents] = useState<Content[] | Error>()
  useEffect(() => {
    campaignService
      .getAllUneditedContent()
      .then(c => setContents(c))
      .catch(e => setContents(e))
  }, [])

  if (contents == null) return <>Laden...</>
  if (contents instanceof Error) return <>Fehler...</>

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Brandname</TableCell>
              <TableCell>Influencer</TableCell>
              <TableCell>Needed Video Package</TableCell>
              <TableCell>Days passed</TableCell>
              <TableCell align='center'>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents.map(c => (
              <TableRow key={c.todoId}>
                <VideoItem content={c} remove={() => setContents(p => (Array.isArray(p) ? p.filter(it => it.todoId != c.todoId) : p))} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
