import { Button, Card, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import agent from '../../agent'
import { Empty } from '../../util'
import { useImmer } from 'use-immer'
import { InternalContent, MessageType } from '../../types/types'

const ContentCard = ({ content: t, remove }: { content: InternalContent; remove(): void }) => {
  const [sending, setSending] = useState(false)
  const [message, setMessage] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const send = async () => {
    setSending(true)
    try {
      await agent.Campaigns.setInternalFeedback({ campaignId: t.campaignId, influencerId: t.influencerId, feedback: message })
      if (message) {
        const { influencerId } = t
        const { conversationId } = await agent.Chat.createConversation({ influencerId })
        await agent.Chat.sendMessage({ conversationId, content: message, type: MessageType.text })
      }
      remove()
    } catch {
      enqueueSnackbar('Error while sending feedback', { variant: 'error' })
    }
    setSending(false)
  }
  if (!t.contentLinks)
    return (
      <Card sx={{ p: 1 }}>
        <Stack spacing={1} alignItems='center'>
          Content Link Missing
        </Stack>
      </Card>
    )

  return (
    <Card sx={{ p: 1 }}>
      <Stack spacing={1} alignItems='center'>
        <ReactPlayer controls url={t.contentLinks[0].link} height={150} width='100%' />
        {sending ? (
          <CircularProgress />
        ) : (
          <Stack alignItems='center' spacing={1}>
            <Typography>
              Rating: {t.rating} {t.ratingFeedback ? `'${t.ratingFeedback}'` : ''}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <TextField fullWidth value={message} onChange={e => setMessage(e.target.value)} multiline />
              {message ? (
                <Button variant='contained' color='success' size='small' onClick={send}>
                  Send
                </Button>
              ) : (
                <Button variant='contained' color='error' size='small' onClick={send}>
                  Ignore
                </Button>
              )}
            </Stack>{' '}
          </Stack>
        )}
      </Stack>
    </Card>
  )
}

export const InternalFeedback = () => {
  const [todos, setTodos] = useImmer<InternalContent[]>([])
  const [state, setState] = useState<Empty | Error>()
  const { enqueueSnackbar } = useSnackbar()
  const load = async () => {
    setState(undefined)
    try {
      const content = await agent.Campaigns.getContentForInternalFeedback()
      setTodos(content)
    } catch (e) {
      setState(e as Error)
      enqueueSnackbar('Error while getting feedback', { variant: 'error' })
    }
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <Stack>
      <Grid container>
        {todos.map(content => (
          <Grid key={`${content.campaignId}_${content.influencerId}`} item xs={12} md={4} lg={3} p={1}>
            <ContentCard content={content} remove={() => setTodos(p => p.filter(t => t.campaignId != content.campaignId && t.influencerId != content.influencerId))} />
          </Grid>
        ))}
      </Grid>
      {state == null && <CircularProgress />}
    </Stack>
  )
}
