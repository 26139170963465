import React, { createContext, useContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import config from '../config'

type CognitoUser = import('amazon-cognito-identity-js').CognitoUser

interface AuthContextType {
  userId: string | null
  token: string | null
  isAuthenticated: boolean
  login: (email: string, password: string) => Promise<CognitoUser>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<string | null>(window.localStorage.getItem('userId'))
  const [token, setToken] = useState<string | null>(window.localStorage.getItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${userId}.idToken`))

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const session = await Auth.currentSession()
        setUserId(session.getIdToken().payload.sub)
        setToken(session.getIdToken().getJwtToken())
      } catch (error) {
        console.error('Failed to fetch current session', error)
      }
    }

    fetchCurrentUser()
  }, [])

  const generatePassword = (length = 20) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='
    let newPassword = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length)
      newPassword += chars[randomIndex]
    }
    return newPassword
  }

  const login = async (email: string, password: string) => {
    const cognitoUser = await Auth.signIn(email, password)
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const newPw = generatePassword()
      console.log(newPw)
      await Auth.completeNewPassword(cognitoUser, newPw)
    }
    const session = await Auth.currentSession()
    setUserId(session.getIdToken().payload.sub)
    setToken(session.getIdToken().getJwtToken())
    window.localStorage.setItem('userId', session.getIdToken().payload.sub)
    window.localStorage.setItem(
      `CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${session.getIdToken().payload.sub}.idToken`,
      session.getIdToken().getJwtToken()
    )
    return cognitoUser as CognitoUser
  }

  const logout = async () => {
    await Auth.signOut()
    setUserId(null)
    setToken(null)
    window.localStorage.clear()
  }

  return <AuthContext.Provider value={{ userId, token, isAuthenticated: token != null, login, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
