import { Grid } from '@mui/material'
import React from 'react'
import CommentsList from './CommentsList'
import { CommentField } from './CommentField'
import { Comment } from '../../types/types'

type Props = {
  comments: Comment[]
  campaignId: string
  influencerId?: string
  handleCommentsChange: (comments: Comment[]) => void
}

export const Comments = (props: Props) => {
  const { comments, campaignId, influencerId, handleCommentsChange } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <CommentsList comments={comments} />
        <CommentField comments={comments} campaignId={campaignId} influencerId={influencerId} onChangeComments={handleCommentsChange} />
      </Grid>
    </Grid>
  )
}
