import { Box, Button, Menu, MenuItem } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

type Config<T extends string> = {
  options: readonly T[]
  setOption?: (_: T) => void
  clipboardSource?: Partial<Record<T, string>>
  children: JSX.Element | string
  ml?: string | number
}
const MenuDrop = <T extends string>({ options, setOption, clipboardSource = {}, children }: Config<T>) => {
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const open = !!anchorEl

  return (
    <Box>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        size='small'
        sx={{ minHeight: 0, minWidth: 0, p: 0, color: 'black' }}
        onClick={e => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}>
        {children}
      </Button>
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(undefined)} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
        {options.map(option => (
          <MenuItem
            key={option}
            onClick={e => {
              e.stopPropagation()
              const value = clipboardSource[option]
              if (value == null) {
                setOption?.(option)
              } else {
                navigator.clipboard.writeText(value)
                enqueueSnackbar('Copied!', { autoHideDuration: 500, variant: 'info' })
              }
              setAnchorEl(undefined)
            }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
export default MenuDrop
