import React from 'react';
import { Grid, Typography } from '@mui/material';
import { HomeCard } from './HomeCard';
import { pages, PageTypes } from '../../Pages';

const Home: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ margin: '16px' }}>
        <Typography variant="h5">Kampagnen Management</Typography>
      </Grid>
      {pages
        .filter(p => p.type === PageTypes.BRANDS)
        .map(p => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={p.value}>
            <HomeCard title={p.title} description={p.description} value={p.value} icon={p.icon} />
          </Grid>
        ))}
      <Grid item xs={12} sx={{ margin: '16px' }}>
        <Typography variant="h5">Influencer Management</Typography>
      </Grid>
      {pages
        .filter(p => p.type === PageTypes.INFLUENCERS)
        .map(p => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={p.value}>
            <HomeCard title={p.title} description={p.description} value={p.value} icon={p.icon} />
          </Grid>
        ))}
      {pages
        .filter(p => p.type === PageTypes.STATISTICS)
        .map(p => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={p.value}>
            <HomeCard title={p.title} description={p.description} value={p.value} icon={p.icon} />
          </Grid>
        ))}
    </Grid>
  );
};

export default Home;
