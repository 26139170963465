import { Dialog, Link, Table, TableBody, TableCell, TableHead, TableRow, DialogContent } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { TransactionDetail } from '../../types/types'

interface ITransactionDetailsDialogProps {
  onClose: () => void
  details?: TransactionDetail
}

const TransactionDetailsDialog = ({ details, onClose }: ITransactionDetailsDialogProps) => {
  return (
    <Dialog open={!!details} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogContent>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>BrandName</TableCell>
              <TableCell>Credits bevor</TableCell>
              <TableCell>Erhaltene Credits</TableCell>
              <TableCell>Credits danach</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details
              ?.sort((a, b) => b.createdAt - a.createdAt)
              .map(detail => (
                <TableRow key={detail.creditHistoryId}>
                  <TableCell>{moment(detail.createdAt).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>{detail.type}</TableCell>
                  <TableCell>{detail.brandName}</TableCell>
                  <TableCell>{detail.amountBefore}</TableCell>
                  <TableCell>{detail.amount}</TableCell>
                  <TableCell>{detail.amountAfter}</TableCell>
                  <TableCell>
                    {detail.brandId && <Link href={`${process.env.REACT_APP_WEB_URL}/content/${detail.brandId}/${detail.campaignId}`}>Zur Kampagne</Link>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

export default TransactionDetailsDialog
