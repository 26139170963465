import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChatConversation as Conversation, ChatMessage, MessageType } from '../types/types'
import agent from '../agent'
import { useSnackbar } from 'notistack'

interface ChatContextType {
  conversations: Conversation[]
  messages: ChatMessage[]
  selectedConvo: Conversation | null
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>
  setSelectedConvo: (convo: Conversation | null, navigateTo?: boolean) => void
  loadConversations: (loadMore?: boolean) => Promise<void>
  loadMessages: (loadMore?: boolean) => Promise<void>
  sendMessage: (conversationId: string, content: string, type: MessageType) => Promise<void>
  markAsUnread: (conversationId: string) => Promise<void>
  lastConversationKey: object | null
  lastMessageKey: object | null
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [conversations, setConversations] = useState<Conversation[]>([])
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const [selectedConvo, setSelectedConvoState] = useState<Conversation | null>(null)
  const [lastConversationKey, setLastConversationKey] = useState<object | null>(null)
  const [lastMessageKey, setLastMessageKey] = useState<object | null>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const markAsUnread = async (conversationId: string) => {
    const key = enqueueSnackbar('Marking as unread...', { variant: 'info' })
    await agent.Chat.markAsUnread({ conversationId })
    setConversations(prevConversations => prevConversations.map(c => (c.conversationId === conversationId ? { ...c, unread: true } : c)))
    closeSnackbar(key)
  }

  const markAsRead = async (conversationId: string) => {
    await agent.Chat.markAsRead({ conversationId })
    setConversations(prevConversations => prevConversations.map(c => (c.conversationId === conversationId ? { ...c, unread: false } : c)))
  }

  const loadMessages = async (loadMore = false) => {
    const key = enqueueSnackbar('Loading messages...', { variant: 'info', persist: true })
    if (!selectedConvo) return
    const { conversationId } = selectedConvo
    console.log(lastMessageKey)
    const params = loadMore && lastMessageKey ? { lastEvaluatedKey: JSON.stringify(lastMessageKey) } : {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await agent.Chat.getMessages({ conversationId, limit: 20, ...(params as any) })
    if (loadMore) {
      setMessages(prevMessages => [...prevMessages, ...response.messages].ascendingSorted(m => m.createdAt))
    } else {
      setMessages(response.messages.ascendingSorted(m => m.createdAt))
    }
    setLastMessageKey(response.lastEvaluatedKey || null)
    closeSnackbar(key)
    await markAsRead(conversationId)
  }

  const setSelectedConvo = (convo: Conversation | null, navigateTo = true) => {
    if (selectedConvo?.conversationId === convo?.conversationId) return
    setSelectedConvoState(convo)
    // is needed for the todo overview
    if (navigateTo)
      if (convo) {
        navigate(`/chat?conversationId=${convo.conversationId}`)
      } else {
        navigate('/chat')
      }
  }

  const loadConversations = async (loadMore = false) => {
    const key = enqueueSnackbar('Loading conversations...', { variant: 'info', persist: true })
    const params = loadMore && lastConversationKey ? { lastEvaluatedKey: JSON.stringify(lastConversationKey) } : {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await agent.Chat.getConversations(params as any)
    const fetchedConversations = response.conversations
    setLastConversationKey(response.lastEvaluatedKey || null)
    if (loadMore) {
      setConversations(prevConversations => [...prevConversations, ...fetchedConversations])
    } else {
      setConversations(fetchedConversations)
    }
    const urlParams = new URLSearchParams(location.search)
    const conversationId = urlParams.get('conversationId')
    if (conversationId) {
      const selected = fetchedConversations.find(c => c.conversationId === conversationId) || null
      setSelectedConvo(selected)
    }
    closeSnackbar(key)
  }

  const sendMessage = async (conversationId: string, content: string, type: MessageType) => {
    const newMessage = await agent.Chat.sendMessage({ conversationId, content, type })
    setMessages(prevMessages => [...prevMessages, newMessage])

    // Move the conversation to the top of the Conversations Previews
    setConversations(prevConversations => {
      const updatedConversations = prevConversations.map(c => (c.conversationId === conversationId ? { ...c, lastMessage: newMessage } : c))
      const movedConversation = updatedConversations.find(c => c.conversationId === conversationId)
      const filteredConversations = updatedConversations.filter(c => c.conversationId !== conversationId)
      return movedConversation ? [movedConversation, ...filteredConversations] : filteredConversations
    })
  }

  useEffect(() => {
    loadConversations()
  }, [])

  useEffect(() => {
    if (selectedConvo) loadMessages()
  }, [selectedConvo])

  return (
    <ChatContext.Provider
      value={{
        conversations,
        messages,
        selectedConvo,
        setConversations,
        setMessages,
        setSelectedConvo,
        loadConversations,
        loadMessages,
        sendMessage,
        markAsUnread,
        lastConversationKey,
        lastMessageKey
      }}>
      {children}
    </ChatContext.Provider>
  )
}

export const useChat = () => {
  const context = useContext(ChatContext)
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider')
  }
  return context
}
