import { Card, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { color } from '../../theme';

interface CardProps {
  title: string;
  description: string;
  value: string;
  icon: IconDefinition;
}

export const HomeCard: React.FC<CardProps> = (props) => {
  const { title, description, value, icon } = props;
  const [raised, setRaised] = useState(false);

  return (
    <div style={{ padding: '16px' }}>
      <Card
        onMouseOver={() => setRaised(true)}
        onMouseOut={() => setRaised(false)}
        raised={raised}
        sx={{ padding: '8px 16px 8px 4px', cursor: 'pointer' }}
      >
        <Link to={`/${value}`}>
          <div>
            <Grid container>
              <Grid item xs={2}>
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                  <FontAwesomeIcon icon={icon} size="lg" />
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle2" sx={{ padding: '4px 0' }}>
                  {title}
                </Typography>
                <Typography variant="body2" sx={{ padding: '4px 0' }}>
                  {description}
                </Typography>
                <Typography variant="subtitle2" sx={{ textTransform: 'uppercase', color: color.stylinkPurple, padding: '4px 0' }}>
                  Weiter
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Link>
      </Card>
    </div>
  );
};
