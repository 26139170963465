import agent from "../agent"
import CampaignService from "../service/CampaignService"

enum Locale {
  LOCAL = 'local',
  DELIVERY = 'delivery'
}

enum UGCStatus {
  Accepted = 'accepted',
  New = 'new',
  Banned = 'banned',
  Premium = 'premium'
}

export enum InfluencerTransactionType {
  TRANSACTION_REQUESTED = 'TRANSACTION_REQUESTED',
  TRANSACTION_FULFILLED = 'TRANSACTION_FULFILLED'
}

export enum Gender {
  ANY = 'any',
  MALE = 'male',
  FEMALE = 'female'
}

export enum InfluencerAttribute {
  CAT = 'cat',
  DOG = 'dog',
  MUM = 'mum',
  CAR = 'car'
}

export enum Service {
  selfService = 'selfService',
  nanoService = 'nanoService'
}

export enum MessageType {
  video = 'VIDEO',
  photo = 'PHOTO',
  text = 'TEXT_MESSAGE'
}

export type Campaign = Awaited<ReturnType<typeof CampaignService.getCampaigns>>[number]

export type NewInfluencer = Awaited<ReturnType<typeof agent.Influencers.getAllNew>>[number]
export type Influencer = Awaited<ReturnType<typeof  agent.Influencers.getAll>>[number] 

export type Comment = Awaited<ReturnType<typeof agent.Comments.addComment>>

export type ChatMessage = Awaited<ReturnType<typeof agent.Chat.getMessages>>['messages'][number]
export type ChatConversation = Awaited<ReturnType<typeof agent.Chat.getConversations>>['conversations'][number];

export type TodoOverviewItem = Awaited<ReturnType<typeof agent.Todo.getTodoOverview>>[number]
export type ShippingCodeDetails = Awaited<ReturnType<typeof agent.Todo.getTodoOverview>>[number]['shippingDetails']

export type TransactionDetail = Awaited<ReturnType<typeof agent.Transactions.getTransactions>>[number]['details']

export type InternalContent = Awaited<ReturnType<typeof agent.Campaigns.getContentForInternalFeedback>>[number]

export { Locale, UGCStatus }