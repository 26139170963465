import { Box, Stack, Typography, Link } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { ChatMessage, MessageType } from '../../types/types'
import { PlayArrow } from '@mui/icons-material'

export const MessageView = ({ message, isCurrentUser }: { message: ChatMessage; isCurrentUser: boolean }) => {
  const dateFormat = 'dddd DD.MM.YYYY'
  const newDay = moment(message.createdAt).format(dateFormat)
  const newTime = moment(message.createdAt).local(true).format('HH:mm')
  const isRead = isCurrentUser ? !!message.readAt : undefined

  return (
    <>
      <Typography variant='caption' fontWeight='900' sx={{ textAlign: 'center' }}>
        {newDay}
      </Typography>
      <Box sx={{ textAlign: isCurrentUser ? 'right' : 'left' }}>
        <Box sx={{ display: 'inline-block', position: 'relative', maxWidth: '70%', backgroundColor: isCurrentUser ? 'yellow' : 'gray', borderRadius: 20 }}>
          <Stack sx={{ display: 'block', textAlign: 'left', p: '1em', lineHeight: '1.5em' }}>
            {message.type == MessageType.text ? (
              message.content?.split('\n').map((c, i) => (
                <Typography key={i} sx={{ color: c ? undefined : 'transparent' }}>
                  {c || 'N'}
                </Typography>
              ))
            ) : message.type == MessageType.video ? (
              <Link component='button' onClick={() => window.open(message.content)}>
                <PlayArrow />
              </Link>
            ) : (
              <img
                onClick={() => window.open(message.content)}
                src={message.content}
                style={{ objectFit: 'cover', objectPosition: 'center', height: 192, width: '100%' }}
              />
            )}
          </Stack>
        </Box>
        <Typography component={'div'} variant='caption'>
          {newTime}
        </Typography>
        {isRead != null && (
          <Typography component='div' variant='caption'>
            {isRead ? 'Read' : 'Delivered'}
          </Typography>
        )}
      </Box>
    </>
  )
}
