import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Box, Stack, Typography } from '@mui/material'
import { Auth } from 'aws-amplify'
import { Comment } from '../../types/types'

export const CommentItem = ({ comment }: { comment: Comment }) => {
  const [isCurrentUser, setIsCurrentUser] = useState(false)
  const { content, author, createdAt } = comment

  const handleIsCurrentUser = async (email: string) => {
    const user = await Auth.currentUserInfo()
    setIsCurrentUser(user.attributes.email === email)
  }

  useEffect(() => {
    handleIsCurrentUser(author.email)
  }, [author.email])

  return (
    <Box sx={{ textAlign: isCurrentUser ? 'right' : 'left' }}>
      <Box sx={{ display: 'inline-block', position: 'relative', maxWidth: '70%', backgroundColor: 'gray', borderRadius: 20 }}>
        <Stack sx={{ display: 'block', textAlign: 'left', p: '1em', lineHeight: '1.5em', color: '#FFF' }}>
          <Typography>{content}</Typography>
        </Stack>
      </Box>
      <Box sx={{ display: 'block' }}>
        <Typography variant='h5' sx={{ fontSize: '0.7rem', ml: '2px', mt: '2px' }}>
          {author.email}
        </Typography>
        <Typography variant='caption' sx={{ fontSize: '0.7rem', ml: '2px', mt: '2px' }}>
          {moment(createdAt).fromNow()}
        </Typography>
      </Box>
    </Box>
  )
}
