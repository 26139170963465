import React, { FormEvent, useState } from 'react'
import { Grid, FormControl, InputLabel, Input, IconButton, InputAdornment, FormHelperText } from '@mui/material'
import visibilityOff from '../../assets/icons/ic_visibility-off.svg'
import visibilityOn from '../../assets/icons/ic_visibility-on.svg'
import nanoLogo from '../../assets/logo/nano_quadrat_sub_form_black@2x.png'
import { useAuth } from '../../context/AuthContext'
import styles from '../../style/auth.module.css'
import { useNavigate } from 'react-router'
import { LoadingButton } from '@mui/lab'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const { login } = useAuth()
  const [email, setEmail] = useState(process.env.LOGIN || '')
  const [password, setPassword] = useState(process.env.PASSWORD || '')
  const [loginError, setLoginError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      await login(email, password)
      navigate('/home')
    } catch (e) {
      console.log(e)
      setLoginError('Benutzername und Passwort stimmen nicht überein')
    } finally {
      setIsLoading(false)
    }
  }

  const renderForm = () => {
    return (
      <Grid className={styles.container} container alignItems='center' alignContent='space-around'>
        <Grid item xs={12} className={styles.logoContainer}>
          <img src={nanoLogo} alt='Nano Logo' className={styles.logo} width='128' />
        </Grid>
        <Grid container alignItems='flex-end' justifyContent='center'>
          <form onSubmit={async e => await handleSubmit(e)} className={styles.form}>
            <Grid item xs={12}>
              <h4>Login</h4>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={styles.emailLabel}>
                <InputLabel>Email</InputLabel>
                <Input error={loginError.length > 0} type='email' value={email} onChange={e => setEmail(e.target.value)} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={styles.passwordLabel}>
                <InputLabel>Password</InputLabel>
                <Input
                  error={loginError.length > 0}
                  aria-describedby='password-error-text'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={() => handleClickShowPassword()} className={styles.noPadding}>
                        {showPassword ? <img src={visibilityOff} alt='visibility on' /> : <img src={visibilityOn} alt='visibility off' />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id='password-error-text' error style={{ margin: '0' }}>
                  {loginError}
                </FormHelperText>
              </FormControl>
            </Grid>
            <div className={styles.buttonDiv}>
              <LoadingButton type='submit' variant='contained' loading={isLoading} className={styles.loginButton} disabled={!(email.length > 0 && password.length > 0)}>
                Anmelden
              </LoadingButton>
            </div>
          </form>
        </Grid>
      </Grid>
    )
  }

  return <div>{renderForm()}</div>
}

export default Login
