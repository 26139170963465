import Enum from '../../types/Enum'
import { TodoOverviewItem } from '../../types/types'

export const Filter = Enum.fromArray('Brand', 'Creator', 'All')
export type Filter = Enum<typeof Filter>
export const Sort = Enum({
  earliest: 'frühestes Enddatum',
  latest: 'spätestes Enddatum',
  answerReceived: 'Antwort erhalten',
  noAnswer: 'keine Antwort',
  creatorAtoZ: 'Creator A-Z',
  creatorZtoA: 'Creator Z-A',
  brandAtoZ: 'Brand A-Z',
  brandZtoA: 'Brand Z-A'
} as const)
export type Sort = Enum<typeof Sort>

export const filter = (filter: Filter, list: TodoOverviewItem[]): TodoOverviewItem[] =>
  Filter.fold(filter, {
    [Filter.Brand]: () => list.filter(item => item.type == 'awaitDelivery'),
    [Filter.Creator]: () => list.filter(item => item.type != 'awaitDelivery'),
    [Filter.All]: () => list
  })

function calculate({ influencerId }: TodoOverviewItem, last: { influencerId: string; lastFromInfluencer: boolean | undefined }[]) {
  const lastFromInfluencer = last.find(p => p.influencerId == influencerId)?.lastFromInfluencer
  if (lastFromInfluencer == null) return 0
  return lastFromInfluencer ? 1 : -1
}
export const sort = (sort: Sort, list: TodoOverviewItem[], last: { influencerId: string; lastFromInfluencer: boolean | undefined }[]): TodoOverviewItem[] =>
  Sort.fold(sort, {
    [Sort.earliest]: () => list.ascendingSorted(t => t.endDate),
    [Sort.latest]: () => list.ascendingSorted(t => t.endDate).reversed(),
    [Sort.brandAtoZ]: () => list.sorted((a, b) => `${b.brandName}: ${b.productName}`.localeCompare(`${a.brandName}: ${a.productName}`)),
    [Sort.brandZtoA]: () => list.sorted((a, b) => `${a.brandName}: ${a.productName}`.localeCompare(`${b.brandName}: ${b.productName}`)),
    [Sort.creatorAtoZ]: () => list.sorted((a, b) => a.fullName.localeCompare(b.fullName)),
    [Sort.creatorZtoA]: () => list.sorted((a, b) => b.fullName.localeCompare(a.fullName)),
    [Sort.noAnswer]: () => list.ascendingSorted(a => calculate(a, last)),
    [Sort.answerReceived]: () => list.ascendingSorted(a => -calculate(a, last))
  })
