import * as Icons from '@mui/icons-material'
import { Button, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { MessageField } from '../chat/MessageField'
import { MessagesView } from '../chat/MessagesView'

const messagePresets: { label: string; text: string }[] = [
  {
    label: 'Schaffst du das Todo on Time?',
    text: `Hey! \nSchaffst du das Todo on Time? \nLG Konrad`
  }
]

export const MessageFieldWithPresets = () => {
  const [showPresets, setShowPresets] = useState(false)
  const [message, setMessage] = useState('')

  return (
    <Box bgcolor='white'>
      <Button size='small' onClick={() => setShowPresets(v => !v)}>
        Show Presets
      </Button>
      <Collapse in={showPresets} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {messagePresets.map(({ label, text }) => (
            <ListItemButton key={label} sx={{ pl: 4 }} onClick={() => setMessage(text)}>
              <ListItemIcon>
                <Icons.StarBorder />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
      <MessageField message={message} setMessage={setMessage} />
    </Box>
  )
}

const ChatView = () => (
  <>
    <MessagesView height='490px' />
    <MessageFieldWithPresets />
  </>
)

export default ChatView
