import agent from '../agent';

class TransactionService {
  getTransactions(isFulfilled: boolean) {
    return agent.Transactions.getTransactions({ isFulfilled: isFulfilled.toString() });
  }

  generateInvoices(transactionIds: string[]) {
    return agent.Transactions.generateInvoices({ transactionIds, invoicesGeneratedBy: undefined });
  }

  markAsSent(transactionIds: string[]) {
    return agent.Transactions.markAsSent({ transactionIds, fulfilledBy: undefined });
  }
}

export default new TransactionService();
