import React, { useRef, useState } from 'react'
import { Send as IconSend } from '@mui/icons-material'
import { IconButton, Stack, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import agent from '../../agent'
import { Auth } from 'aws-amplify'
import { Comment } from '../../types/types'

type Props = {
  comments: Comment[]
  campaignId: string
  influencerId?: string
  onChangeComments: (value: Comment[]) => void
}

export const CommentField = (props: Props) => {
  const { comments, campaignId, influencerId, onChangeComments } = props
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const messageField = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')

  const handleContentChange = (value: string) => setContent(value)

  const clearComment = () => {
    handleContentChange('')
    if (messageField.current) messageField.current.value = ''
  }

  const handleAddComment = async () => {
    const user = await Auth.currentUserInfo()
    setLoading(true)
    if (user && content) {
      try {
        const key = enqueueSnackbar('Adding Comment...', { variant: 'info', persist: true })
        const payload: Partial<Comment> = { influencerId, campaignId, content, author: { email: user.attributes.email } }
        const addedComment = await agent.Comments.addComment(payload as Comment)
        onChangeComments([...comments, addedComment])
        closeSnackbar(key)
        clearComment()
      } catch (error) {
        console.log('Add comment error', error)
        enqueueSnackbar('Failed to send', { variant: 'error' })
      } finally {
        closeSnackbar()
        setLoading(false)
      }
    }
  }

  return (
    <Stack direction='row' paddingTop={2} alignItems='center' spacing={1}>
      <TextField
        value={content}
        fullWidth
        multiline
        label='Leave a comment'
        variant='outlined'
        style={{ maxWidth: '100%' }}
        onChange={e => handleContentChange(e.target.value)}
      />
      <IconButton size='small' sx={{ minHeight: 0, minWidth: 0 }} onClick={handleAddComment} disabled={loading || !content}>
        <IconSend />
      </IconButton>
    </Stack>
  )
}
