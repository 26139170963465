import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Login from './components/auth/Login'
import PrivateRoute from './route/PrivateRoute'
import PublicRoute from './route/PublicRoute'
import Home from './components/home/Home'
import './App.css'
import NewInfluencerList from './components/influencers/verification/NewInfluencerList'
import Transactions from './components/transactions/Transactions'
import CampaignsTable from './components/campaignsTable/CampaignsTable'
import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import Chat from './components/chat/Chat'
import { VideoEditing } from './components/videoEditing/VideoEditing'
import TodoOverview from './components/todoOverview/TodoOverview'
import { AcceptContent } from './components/acceptContent/AcceptContent'
import { InternalFeedback } from './components/internalFeedback/InternalFeedback'
import Credit from './components/influencers/credit/Credit'
import { InfluencerList } from './features/influencers/InfluencerList'
import { InvoiceList } from './features/influencers/InvoiceList'
import InfluencerCampaigns from './features/influencers/InfluencerCampaigns'

export const App = () => (
  <ThemeProvider theme={theme}>
    <Routes>
      <Route path='/login' element={<PublicRoute childComponent={<Login />} />} />
      <Route path='/home' element={<PrivateRoute childComponent={<Home />} />} />
      <Route path='/campaigns' element={<PrivateRoute childComponent={<CampaignsTable />} />} />
      <Route path='/content-check' element={<PrivateRoute childComponent={<AcceptContent />} />} />
      <Route path='/influencers' element={<PrivateRoute childComponent={<InfluencerList />} />} />
      <Route path='/influencers/campaigns' element={<PrivateRoute childComponent={<InfluencerCampaigns />} />} />
      <Route path='/invoices' element={<PrivateRoute childComponent={<InvoiceList />} />} />
      <Route path='/influencers-new' element={<PrivateRoute childComponent={<NewInfluencerList />} />} />
      <Route path='/todo-overview' element={<PrivateRoute childComponent={<TodoOverview />} />} />
      <Route path='/transactions' element={<PrivateRoute childComponent={<Transactions />} />} />
      <Route path='/chat/:influencerId?' element={<PrivateRoute childComponent={<Chat />} />} />
      <Route path='/video-editing' element={<PrivateRoute childComponent={<VideoEditing />} />} />
      <Route path='/internal-feedback' element={<PrivateRoute childComponent={<InternalFeedback />} />} />
      <Route path='/credit' element={<PrivateRoute childComponent={<Credit />} />} />
      <Route path='*' element={<Navigate to='/home' />} />
    </Routes>
  </ThemeProvider>
)
