import { Equalizer, Person, PersonAdd, PersonAddDisabled } from '@mui/icons-material'
import { Box, Grid, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { Comment as CommentIcon } from '@mui/icons-material'
import CommentDialog from '../../comments/CommentDialog'
import { Campaign } from '../../../types/types'
interface Props {
  campaigns: Campaign[]
  loadMore: () => void
  refetchComments: () => void
  open: string | false
  onChangeOpen: (value: string | false) => void
}

const OfflineBody = ({ open, onChangeOpen, campaigns }: Props) => {
  return (
    <TableBody>
      {campaigns.map(campaign => (
        <TableRow key={campaign.campaignId + campaign.brandId}>
          <TableCell align='left'>
            <Grid container alignItems='left'>
              <Box
                component='img'
                src={campaign.brandLogo}
                alt='influencerpic'
                sx={{ maxWidth: '4em', maxHeight: '3em', width: 'auto', height: 'auto', paddingRight: '5px' }}
              />
              {campaign.brandName}
            </Grid>
          </TableCell>
          <TableCell align='left'>
            <Grid container alignItems='left'>
              <Box
                component='img'
                src={campaign.campaignImage}
                alt='influencerpic'
                sx={{ maxWidth: '4em', maxHeight: '3em', width: 'auto', height: 'auto', paddingRight: '5px' }}
              />{' '}
              <a target='_blank' rel='noopener noreferrer' href={`${process.env.REACT_APP_WEB_URL}/content/${campaign.brandId}/${campaign.campaignId}/applications`}>
                {campaign.title}
              </a>
            </Grid>
          </TableCell>
          <TableCell align='left'>
            <Grid container direction='column' alignItems='left'>
              <PersonAdd />
              {campaign.applicants}
            </Grid>
          </TableCell>
          <TableCell align='left'>
            <Grid container direction='column' alignItems='left'>
              <Person />
              {campaign.completedParticipants}/{campaign.participants}
            </Grid>
          </TableCell>
          <TableCell align='left'>
            <Grid container direction='column' alignItems='left'>
              <Equalizer />
              {campaign.notPayed}
            </Grid>
          </TableCell>
          <TableCell align='left'>
            <Grid container direction='column' alignItems='left'>
              <PersonAddDisabled />
              {campaign.deniedApplicants}
            </Grid>
          </TableCell>
          {/* <TableCell align='left'>
            <Grid container direction='column' alignItems='left'>
              <FormControl>
                <InputLabel id='demo-simple-select-label'></InputLabel>
              </FormControl>
            </Grid>
          </TableCell> */}
          <TableCell align='left'>
            <Box sx={{ cursor: 'pointer' }} onClick={() => onChangeOpen(open === campaign.campaignId ? false : campaign.campaignId)}>
              <CommentIcon color={open === campaign.campaignId ? 'info' : undefined} />
            </Box>
            {open === campaign.campaignId && (
              <CommentDialog open={open} onClose={() => onChangeOpen(false)} id={campaign.campaignId} campaignId={campaign.campaignId} comments={campaign.comments} />
            )}
          </TableCell>
        </TableRow>
      ))}
      {/* <Waypoint scrollableAncestor={window} onEnter={() => loadMore()}>
        <p>Loading More...</p>
      </Waypoint> */}
    </TableBody>
  )
}

export default OfflineBody
