import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CampaignCard from './CampaignCard'
import { Filter, Sort, filter, sort } from './FilterAndSort'
import InfluencerCard from './InfluencerCard'
import MenuDrop from './MenuDrop'
import TodoCard from './TodoCard'
import Actions from './TodoActions'
import { useChat } from '../../context/ChatContext'
import { TodoOverviewItem } from '../../types/types'
import { TodoOverviewProvider, useTodoOverview } from '../../context/TodoOverviewContext'

const Row = ({ item }: { item: TodoOverviewItem }) => {
  const { conversations } = useChat()
  const lastMessageAuthor = conversations.find(c => c.influencerId === item.influencerId)?.lastMessage?.authorId
  const backgroundColor = lastMessageAuthor ? (lastMessageAuthor === item.influencerId ? '#d1d8ff' : '#feffc3') : undefined
  return (
    <TableRow sx={{ opacity: '1', backgroundColor }}>
      {[TodoCard, CampaignCard, InfluencerCard, Actions].map(Comp => (
        <TableCell key={Comp.name}>
          <Comp item={item} />
        </TableCell>
      ))}
    </TableRow>
  )
}

const Rows = ({ sortType, filterType }: { filterType: Filter; sortType: Sort }) => {
  const { items } = useTodoOverview()
  const { conversations, loadConversations } = useChat()
  const { loadItems } = useTodoOverview()

  const last = conversations.map(c => {
    const last = c?.lastMessage
    return { influencerId: c.influencerId, lastFromInfluencer: last && last.authorId === c.influencerId }
  })

  useEffect(() => {
    if (!conversations.length) loadConversations()
    loadItems()
  }, [])

  return (
    <>
      {sort(sortType, filter(filterType, items), last).map(item => (
        <Row key={item.todoId} item={item} />
      ))}
    </>
  )
}

const TodoOverview = () => {
  const [filterType, setFilter] = useState<Filter>(Filter.All)
  const [sortType, setSort] = useState<Sort>(Sort.earliest)

  return (
    <TodoOverviewProvider>
      <TableContainer component={Paper} sx={{ minWidth: 650, height: '100%' }}>
        <Table size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '20%' }}>Todo</TableCell>
              <TableCell sx={{ width: '30%' }}>Kampagne</TableCell>
              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr='auto'>Creator</Box>
                <MenuDrop setOption={setFilter} options={Filter.values}>
                  {`Filter : ${filterType ?? ''}`}
                </MenuDrop>
                <Box width='20px' />
                <MenuDrop setOption={setSort} options={Sort.values}>
                  {`Sortieren : ${sortType ?? ''}`}
                </MenuDrop>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Rows filterType={filterType} sortType={sortType} />
          </TableBody>
        </Table>
      </TableContainer>
    </TodoOverviewProvider>
  )
}

export default TodoOverview
