import { Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import influencerService from '../../../service/InfluencerService'
import { UGCStatus } from '../../../types/types'
import { extractUserMessageFromError } from '../../../util'
import ErrorScreen from '../../common/ErrorScreen'
import LoadingScreen from '../../common/LoadingScreen'
import InfluencerCard from './NewInfluencerCard'
import { NewInfluencer as Influencer } from '../../../types/types'

const NewInfluencerList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [influencersUgc, setInfluencerUgc] = useState<Influencer[] | undefined>(undefined)
  const [influencersLoading, setInfluencersLoading] = useState<Influencer[]>([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const loadInfluencers = async () => {
    try {
      setLoading(true)
      const response = await influencerService.getInfluencersNew()
      setInfluencerUgc(response)
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadInfluencers()
  }, [])

  const acceptInfluencer = async (influencer: Influencer) => {
    setInfluencersLoading(prev => [...prev, influencer])

    const key = enqueueSnackbar(`${influencer.stylinkData.payment.first_name} annehmen lädt`, { variant: 'info', autoHideDuration: null })

    try {
      await influencerService.verifyInfluencer(influencer)
      if (influencersUgc) {
        setInfluencerUgc(prev => (prev ? [...prev.filter(i => i !== influencer)] : undefined))
      }

      enqueueSnackbar(`${influencer.stylinkData.payment.first_name} annehmen erfolgreich`, { variant: 'success' })
    } catch (e) {
      console.log(e)
      const message = extractUserMessageFromError(e)
      if (message) {
        enqueueSnackbar(message, { variant: 'error' })
      } else {
        enqueueSnackbar(`Beim Annehmen von ${influencer.stylinkData.payment.first_name} ist ein Fehler aufgetreten`, { variant: 'error' })
      }
    } finally {
      closeSnackbar(key)
      setInfluencersLoading(prev => [...prev.filter(i => i !== influencer)])
    }
  }

  const denyInfluencer = async (influencer: Influencer, status?: UGCStatus) => {
    setInfluencersLoading(prev => [...prev, influencer])

    const key = enqueueSnackbar(`${influencer.stylinkData.payment.first_name} lädt`, {
      variant: 'info',
      autoHideDuration: null
    })

    try {
      await influencerService.denyInfluencer({ influencerId: influencer.influencerId, ugcStatus: status || UGCStatus.New })

      if (influencersUgc) {
        setInfluencerUgc(prev => (prev ? [...prev.filter(i => i !== influencer)] : undefined))
      }

      enqueueSnackbar(`${influencer.stylinkData.payment.first_name} ablehnen erfolgreich`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Beim Ablehnen von ${influencer.stylinkData.payment.first_name} ist ein Fehler aufgetreten`, { variant: 'error' })
    } finally {
      closeSnackbar(key)
      setInfluencersLoading(prev => [...prev.filter(i => i !== influencer)])
    }
  }

  if (loading) return <LoadingScreen />

  if (error || !influencersUgc) return <ErrorScreen message='Fehler beim Laden' />

  if (influencersUgc.length == 0) return <ErrorScreen message='Keine neuen Nanos' />

  return (
    <Grid container>
      {influencersUgc
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .map(i => {
          const isLoading = influencersLoading.includes(i)
          return (
            <InfluencerCard
              key={i.influencerId}
              influencer={i}
              acceptInfluencer={async () => {
                if (!isLoading) await acceptInfluencer(i)
              }}
              denyInfluencer={async status => {
                if (!isLoading) await denyInfluencer(i, status)
              }}
            />
          )
        })}
    </Grid>
  )
}

export default NewInfluencerList
