import React, { useEffect, useRef } from 'react'
import { State } from '../../util'

export const InputFile = ({ open, setOpen, onFile }: State<'open', boolean> & { onFile: (_: File) => void }) => {
  const inputFile = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (open) inputFile.current?.click()
  }, [open])
  return (
    <input
      type='file'
      ref={inputFile}
      style={{ display: 'none' }}
      onChange={e => {
        const file = e.target.files?.[0]
        if (file) onFile(file)
        setOpen(false)
      }}
    />
  )
}
